@import 'variables';

.inquiry-confirm-page {
  &__confirmation-warning {
    font-size:      14px;
    padding-bottom: 10px;
    padding-top:    10px;
  }

  &__table {
    margin-bottom: 20px;
  }

  &__submit {
    margin-bottom: 20px;
    width:         100%;
  }
}
