@import 'variables';

.inquiry-block-setting {
  &__text-area {
    margin-bottom: 50px;
  }

  &__link-area {
    display:         flex;
    justify-content: space-between;
    margin:          0 auto;
    width:           50%;
  }

  %submit {
    border-radius: $base-border-radius;
    cursor:        pointer;
    display:       block;
    font-size:     20px;
    padding:       20px 60px;
  }

  &__submit {
    @extend %submit;
    background-color: $primary-color;
    box-shadow:       0 2px 4px $shadow-color;
    color:            $white;

    &--disabled {
      @extend %submit;
      background-color: $light-gray-color;
      color:            $text-color;
    }
  }
}
