@import 'variables';

.new-amana-image-page {
  &__search-form {
    margin-bottom: 20px;
  }

  &__search-button {
    margin-left: 20px;
  }

  &__image-list {
    align-items:     center;
    border:          1px solid $gray-color;
    border-radius:   $base-border-radius;
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-around;
    margin-bottom:   20px;
    padding:         10px 0;
    width:           100%;
  }

  &__searched-image {
    cursor: pointer;
  }

  &__next-button {
    display: block;
    margin:  0 auto;
    width:   300px;
  }

  &__selected-image-wrapper {
    background-color: $skeleton-gray-color;
    bottom:           0;
    left:             0;
    padding:          20px;
    position:         fixed;
    right:            0;
    text-align:       center;
    width:            250px;
    z-index:          2;
  }

  &__selected-image {
    margin-bottom: 10px;
  }
}
