@import 'variables';

.area-select {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;

  &__button {
    background-color: $white;
    border:           2px solid $primary-color;
    border-radius:    $base-border-radius;
    color:            $text-color;
    cursor:           pointer;
    font-size:        18px;
    font-weight:      bold;
    margin-bottom:    10px;
    margin-right:     10px;
    padding:          25px 15px;
    position:         relative;
    text-align:       center;
    width:            45%;

    @include media-query(xs) {
      margin-bottom: 15px;
    }

    &:nth-of-type(even) {
      margin-right: 0;
    }

    &::after {
      border-color: transparent $attention-color $attention-color transparent;
      border-style: solid;
      border-width: 10px;
      bottom:       0;
      content:      '';
      position:     absolute;
      right:        0;
    }
  }
}
