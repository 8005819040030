@import 'variables';

.facility-list-search {
  background-color: $white;
  border-radius:    10px;

  &__title {
    background-color:        $primary-color;
    border-top-left-radius:  10px;
    border-top-right-radius: 10px;
    color:                   $white;
    font-size:               20px;
    font-weight:             700;
    padding:                 20px;
    text-align:              center;
  }

  &__content {
    padding: 20px;
  }

  %selected {
    font-weight:   700;
    line-height:   $large-line-height;
    margin-bottom: 20px;
  }

  &__selected {
    &--on {
      @extend %selected;
      color: $text-color;
    }

    &--none {
      @extend %selected;
      color: $light-red-color;

      @include media-query(xs) {
        color: $text-color;
      }
    }
  }

  &__button-wrapper {
    margin: 0 auto;
    max-width: 230px;
  }
}
