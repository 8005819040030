@import 'variables';
@import 'background';

.purpose-select-wrapper {
  @extend %green-dot-background;
  border:        3px solid $light-gray-color;
  border-radius: $base-border-radius;
  height:        420px;
  margin-bottom: 30px;
  margin-right:  calc(15px / 4);
  padding:       30px;

  @include media-query(xs) {
    border:        0;
    height:        auto;
    margin-bottom: 0;
    margin-right:  -15px;
    padding:       30px 15px;
  }

  &__header {
    margin-bottom: 15px;
  }

  &__buttons {
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
  }

  &__button {
    margin-bottom: 15px;
  }
}
