@import 'variables';

.facility-support-article-category-form {
  &__area {
    margin-top: 30px;
  }

  &__field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }

  &__text-field {
    display:       block;
    height:        120px;
    margin-bottom: 50px;
    width:         100%;
  }

  &__submit-buttons {
    display:         flex;
    justify-content: space-between;
    margin:          0 auto;
    width:           50%;
  }

  %submit {
    border-radius: $base-border-radius;
    cursor:        pointer;
    display:       block;
    font-size:     20px;
  }

  &__submit {
    @extend %submit;
    background-color: $primary-color;
    box-shadow:       0 2px 4px $shadow-color;
    color:            $white;
  }

  &__sub-submit {
    @extend %submit;
    background-color: $light-gray-color;
    color:            $text-color;
  }

  &__sub-button {
    height:     70px;
    margin-top: 100px;
    width:      100px;
  }
}
