@import 'variables';

.left-align-secondary-header {
  padding: 15px 0;

  &__text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;
  }

  &__bottom-line {
    border-top: 3px solid $primary-color;
    margin-top: 5px;
    width:      45px;
  }

  &__required {
    margin-left: 5px;
  }
}
