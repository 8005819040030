@import 'variables';

.navigation {
  margin-bottom: 30px;

  &__box {
    border:        2px solid $text-color;
    border-radius: $base-border-radius;
    padding:       20px;
    text-align:    center;
  }

  &__title-text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;
  }

  &__attention-text {
    color:       $light-red-color;
    font-size:   14px;
    font-weight: 700;
    margin-top:  20px;
  }

  &__submit {
    margin-top: 20px;
  }
}
