@import 'variables';

.admin-top-page {
  &__section {
    margin-bottom: 20px;
  }

  &__link-wrapper {
    margin: 10px 0;
  }
}
