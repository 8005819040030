@import 'variables';

.facility-access {
  background-color: $white;
  padding:          0 0 15px;

  &__header {
    margin: 40px 0 15px;
  }

  &__map-wrapper {
    margin-bottom: 25px;
  }

  &__map {
    height:        300px;
    margin-bottom: 15px;
    width:         100%;
  }

  &__address {
    margin-bottom: 15px;
  }

  &__map-link-wrapper {
    margin-top: -40px;
    text-align: right;
  }

  &__map-link {
    font-size:       14px;
    text-decoration: underline;
  }
}
