@import 'variables';

.footer-to-b-facility-banner-list {
  padding-bottom: 50px;

  &--with_large_padding { 
    padding-bottom: 90px;
    
    @include media-query(xs) {
      padding-bottom: 50px;
    }
  }
}
