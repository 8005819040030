@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~@draft-js-plugins/side-toolbar/lib/plugin.css';
@import '~@draft-js-plugins/inline-toolbar/lib/plugin.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~cropperjs/dist/cropper.css';
@import './stylesheets/*';

@font-face {
  font-display: swap;
  font-family: SnabiNotoSansCJKjp;
  font-weight: 400;
  src:         url('./fonts/NotoSansCJKjp-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: SnabiNotoSansCJKjp;
  font-weight: 700;
  src:         url('./fonts/NotoSansCJKjp-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: SnabiNotoSansCJKjp;
  font-weight: 900;
  src:         url('./fonts/NotoSansCJKjp-Black.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background-color:      white;
  color:                 #333;
  font-family:           SnabiNotoSansCJKjp, sans-serif;
  font-feature-settings: 'palt';
  line-height:           $base-line-height;
  word-break:            break-all;
}

// reset cssに書いてないから追加した
a {
  color:           inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

input[type="submit"],
input[type="button"] {
  appearance: button;
  box-sizing: border-box;

  &::-webkit-search-decoration {
    display: none;
  }

  &:focus {
    outline-offset: -2px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin:     0;
}

input[type="number"] {
  appearance: textfield;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
}

button {
  appearance:       none;
  background-color: transparent;
  border:           none;
  cursor:           pointer;
  outline:          none;
  padding:          0;

  &:focus-visible {
    outline: 1px solid -webkit-focus-ring-color;
  }
}

// Railsがバリデーションエラーのlabelとinputに自動で追加するdivに当たってるクラス
.field_with_errors {
  background-color: $light-red-color;
  padding:          5px;
}

// material-tableのfilterComponent全体に当たっているクラス
// 項目名が長い場合に途切れて表示されなくなってしまう問題の対応のため
div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: auto !important;
}
