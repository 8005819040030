@import 'variables';

.select-conditions-page {
  padding-top: 25px;

  &__header {
    margin-bottom: 10px;
  }

  &__second-header {
    margin-bottom: 20px;
  }

  &__check-box-wrapper {
    display:   flex;
    flex-wrap: wrap;
  }

  &__check-box {
    margin-bottom: 30px;
    width:         33%;
  }
}
