@import 'variables';

.editor-toolbar-link-input {
  display:    inline-flex;
  position:   absolute;
  width:      500px;

  &__input {
    background:    $skeleton-gray-color;
    border:        0;
    border-radius: $base-border-radius 0 0 $base-border-radius;
    box-sizing:    border-box;
    color:         $white;
    display:       block;
    padding:       10px;
    width:         100%;
  }

  &__submit {
    background:    $text-color;
    border-radius: 0 $base-border-radius $base-border-radius 0;
    color:         $white;
    cursor:        pointer;
    padding:       0 5px;
    text-align:    center;
  }

  &__error {
    color:      $red;
    position:   relative;
    text-align: center;
  }

  &__error-text {
    border:        $red solid 1px;
    border-radius: $base-border-radius;
    bottom:        110%;
    font-size:     14px;
    margin-left:   -200px;
    padding:       5px;
    position:      absolute;
    width:         250px;
    z-index:       1;
  }
}
