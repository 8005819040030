@import 'variables';

.editor-picture-reference-input {
  &__forms {
    padding: 20px;
  }

  &__form {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
    padding:         10px;
  }

  &__field {
    width: 70%;
  }

  &__submit {
    text-align: center;
  }
}
