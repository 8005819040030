@import 'variables';

.account-form {
  padding: 20px 0;

  &__select {
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__email {
    margin-bottom: 10px;
  }

  &__name {
    margin-bottom: 10px;
  }

  &__per-price {
    margin-bottom: 10px;
  }

  &__assigned-item {
    margin-bottom: 10px;
  }
}
