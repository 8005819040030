@import 'variables';

.facility-card-recommend {
  background-color: $white;
  border-radius:    10px;
  padding:          40px 45px;

  @include media-query(xs) {
    padding: 20px 15px;
  }

  &__name-wrapper {
    align-items:   baseline;
    display:       flex;
    margin-bottom: 5px;
  }

  &__dot {
    color:         $primary-color;
    font-size:     14px;
    padding-right: 5px;
  }

  &__name {
    color:       $text-color;
    font-size:   18px;
    font-weight: 700;
  }

  &__picture-box {
    display:       flex;
    margin-bottom: 10px;
  }

  &__picture {
    margin-bottom: 20px;
    position:      relative;
    width:         100%;
  }

  &__introduction {
    color:         $text-color;
    font-size:     16px;
    font-weight:   700;
    margin-bottom: 20px;
  }

  &__button-wrapper {
    display:         flex;
    justify-content: center;
  }

  &__inquiry-buttons {
    max-width: 280px;
    width:     55%;
  }

  %inquiry-button {
    border:          3px solid $attention-color;
    border-radius:   25px;
    color:           $text-color;
    flex-direction:  column;
    font-weight:     700;
    height:          100%;
    justify-content: center;
    line-height:     1;
    padding:         6px 13px 6px 30px;
    position:        relative;
    text-align:      center;
    width:           100%;
  }

  &__check-box-wrapper {
    border-top:  2px solid $pale-gray-color;
    margin:      15px -15px 0;
    padding-top: 15px;
    text-align:  center;
  }
}
