@import 'variables';

.yellow-circle-black-arrow {
  display: inline-block;

  &__arrow {
    color:       $attention-color;
    font-size: 1.8em;
    font-weight: 700;
  }

  &__circle {
    color: $text-color;
    font-size: 1.5em;
  }
}
