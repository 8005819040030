@import 'variables';

.biz-not-input-message {
  border:        1px solid $text-color;
  border-radius: $base-border-radius;
  padding:       20px;

  %__title {
    font-size:     20px;
    font-weight:   700;
    margin-bottom: 10px;

    &::before {
      content:      '\f06a';
      display:      inline-block;
      font:         normal 600 normal 20px/1 "Font Awesome 5 Free";
      margin-right: 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    @extend %__title;

    &--required {
      @extend %__title;
      color: $light-red-color;
    }

    &--optional {
      @extend %__title;
      color: $gray-color;
    }
  }

  &__image-wrapper {
    margin-right: 20px;
    width:        30%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__image {
    @include object-fit-cover();
    height: auto;
    width:  100%;
  }

  &__body {
    align-items: stretch;
    display:     flex;
    margin-top:  20px;

    @include media-query(xs) {
      flex-direction: column;
    }
  }

  &__text-wrapper {
    flex:        1;
    line-height: $large-line-height;
  }

  %__text {
    font-size: 16px;
  }

  &__text {
    @extend %__text;
    color: $text-color;

    &--bold {
      @extend %__text;
      font-weight: 700;
    }

    &--attention {
      @extend %__text;
      color:       $light-red-color;
      font-weight: 700;
    }
  }
}
