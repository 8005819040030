@import 'variables';

.inquiry-step-progress {
  align-items:     center;
  display:         flex;
  justify-content: center;
  padding-top:     15px;

  &__step {
    text-align: center;
    width:      45px;
  }

  %number {
    align-items:     center;
    border-radius:   50%;
    display:         flex;
    height:          40px;
    justify-content: center;
    margin:          0 auto 10px;
    padding:         5px;
    width:           40px;
  }

  &__number {
    @extend %number;
    background: $white;
    border:     1px solid $primary-color;
    color:      $primary-color;

    &--current {
      @extend %number;
      background: $primary-color;
      border:     1px solid $white;
      color:      $white;
    }
  }

  &__text {
    color:     $primary-color;
    font-size: 8px;
  }

  &__dot {
    align-self:     start;
    color:          $gray-color;
    font-size:      12px;
    letter-spacing: -4px;
    margin:         0 10px;
    padding-top:    15px;
  }
}
