@import 'variables';

.password-reset-page {
  &__attention {
    color:         $light-red-color;
    margin-bottom: 10px;
  }

  &__input {
    margin-bottom: 30px;
    width:         100%;
  }

  &__submit {
    margin-bottom: 20px;
    text-align:    center;
  }

  &__link {
    color:           $text-color;
    text-align:      center;
    text-decoration: underline;
  }
}
