@import 'variables';

.intermediary-inquiry {
  &__header {
    height: 50px;
  }

  &__footer {
    margin-top: 50px;
  }
}
