@import 'variables';

.editor-add-button {
  left:       -50px;
  position:   absolute;
  transition: all 0.2s ease;
  z-index:    1;

  &__plus-button {
    font-size:     30px;
    height:        30px;
    margin-bottom: 5px;
    width:         30px;
  }

  &__side-button {
    background:    none;
    border:        1px solid $text-color;
    border-radius: 15px;
    color:         $text-color;
    cursor:        pointer;
    font-size:     16px;
    font-weight:   700;
    height:        30px;
    margin:        4px;
    text-align:    center;
    width:         30px;
  }
}
