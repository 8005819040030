@import 'variables';

.disability-value {
  align-content: space-between;
  align-items:   center;
  display:       flex;
  flex-wrap:     wrap;

  %label {
    border-radius: $base-border-radius;
    border-style:  solid;
    border-width:  2px;
    font-size:     14px;
    line-height:   1;
    margin:        0 8px 8px 0;
    max-width:     110px;
    padding:       13px 0;
    text-align:    center;
    width:         30%;

    @include media-query(sm) {
      height:      30px;
      line-height: 26px;
      margin:      0 6px 6px 0;
      max-width:   100px;
      padding:     0;
    }

    @include media-query(xs) {
      height:      30px;
      line-height: 26px;
      margin:      0 6px 6px 0;
      max-width:   100px;
      padding:     0;
    }
  }

  &__label {
    &--true {
      @extend %label;
      border-color: $primary-color;
      color:        $text-color;
    }

    &--false {
      @extend %label;
      border-color: $light-gray-color;
      color:        $light-gray-color;
    }
  }
}
