@import 'variables';

.article-list {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: space-between;
  padding:         15px 0;

  @include media-query(xs) {
    padding: 0 10px;
  }

  %item {
    border-bottom: 3px solid $light-gray-color;
    margin-bottom: 15px;
    width:         49%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__item {
    @extend %item;

    &--pickup {
      @extend %item;
      background-color: $pale-green-color;
      border-bottom:    3px solid $primary-color;

      @include media-query(xs) {
        background-color: $white;
      }
    }
  }

  &__button-area {
    margin: 50px auto 0;
    width:  30%;

    @include media-query(xs) {
      margin: 30px auto 0;
      width:  60%;
    }
  }
}
