@import 'variables';

.administer-frequently-asked-question-categories-index {
  &__button {
    margin-bottom: 30px;
  }

  &__header {
    margin-bottom: 15px;
  }
}
