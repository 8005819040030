@import 'variables';

.announcement {
  background-color: $white;
  border: 1px solid $gray-color;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  &__created-at {
    color: $text-color;
    width: 180px;
  }

  &__title {
    color: $text-color;
    flex: 1;
  }

  &__arrow {
    color: $attention-color;
    padding-left: 10px;
    width: 30px;
  }
}
