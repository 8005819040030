@import 'variables';

.biz-client-manual {
  &__link {
    color:           $text-color;
    display:         block;
    margin-bottom:   30px;
    text-decoration: underline;
  }
}
