@import 'variables';

.article-show-article-link {
  background-color: $pale-green-color;
  border-bottom:    3px solid $light-gray-color;
  display:          flex;
  padding:          15px;

  &__picture {
    @include object-fit-cover();
    height:       auto;
    margin-right: 20px;
    width:        100%;

    @include media-query(xs) {
      height: 100px;
    }
  }

  &__picture-wrapper {
    margin-right: 15px;
    width:        30%;
  }

  &__text {
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
    width:           70%;

    @include media-query(xs) {
      width: 60%;
    }
  }

  &__title {
    flex-grow:   5;
    font-size:   18px;
    font-weight: 700;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__link-wrapper {
    flex-grow:      1;
    overflow:       hidden;
    text-align:     right;
    vertical-align: bottom;
  }

  &__link {
    text-decoration: underline;
  }

  &__read-more-icon {
    display:  inline-block;
    height:   24px;
    position: relative;
    width:    24px;

    &::before {
      color:    $text-color;
      content:  '\f111';
      font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
      left:     3px;
      position: absolute;
      top:      7px;
    }

    &::after {
      color:    $attention-color;
      content:  '\f138';
      font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
      left:     1px;
      position: absolute;
      top:      5px;
    }
  }
}
