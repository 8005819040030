@import 'variables';

.administer-facility-brand-form {
  &__text-field {
    width: 100%;
  }

  &__text-area {
    height: 150px;
    width: 100%;
  }
}
