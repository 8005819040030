@import 'variables';

.thirdly-header {
  align-items: center;
  display:     flex;
  padding:     10px 0;

  &__icon {
    color:        $primary-color;
    font-size:    18px;
    margin-right: 10px;
  }

  &__text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;
  }

  &__supplement {
    color:       $text-color;
    font-size:   18px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }
}
