@import 'variables';

.contact {
  margin: 0 auto;
  width:  75%;

  @include media-query(xs) {
    width: 100%;
  }

  &__form {
    background-color: $white;
  }

  &__header-area {
    margin-bottom: 5px;
  }

  &__header {
    display: inline-block;
  }

  &__select {
    background: $white;
    font-size:  16px;
    width:      125px;

    @include media-query(xs) {
      width: auto;
    }
  }

  &__row {
    margin-bottom: 10px;
  }

  &__label {
    display:      inline-block;
    font-size:    14px;
    font-weight:  700;
    margin-right: 10px;
    width:        15%;

    @include media-query(xs) {
      font-size:    14px;
      margin-right: 5px;
      width:        22%;
    }
  }

  &__attention {
    color:         $light-red-color;
    display:       block;
    font-size:     12px;
    margin-bottom: 20px;
  }

  %input {
    border:        1px solid $gray-color;
    border-radius: 3px;
  }

  &__input {
    @extend %input;
    flex: 1;
  }

  &__input-wrapper {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
    margin-bottom:   20px;
  }

  &__text-area {
    height: 100px;
    width:  100%;
  }

  &__email-field {
    width: 100%;
  }

  &__email-row {
    margin-bottom: 20px;
  }

  &__submit-button {
    width: 100%;
  }
}
