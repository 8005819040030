@import 'variables';

.railway-station-checkboxes {
  align-self: center;
  display:    flex;
  padding:    10px;
  width:      30%;

  @include media-query(xs) {
    padding: 10px 0;
    width:   50%;
  }

  &__label {
    flex: 1;
  }
}
