@import 'variables';

.facility-image {
  height:      0;
  overflow:    hidden;
  padding-top: 56.25%;
  position:    relative;

  &__content {
    @include object-fit-cover();
    border:   1px solid $pale-gray-color;
    height:   100%;
    left:     0;
    position: absolute;
    top:      0;
    width:    100%;
  }
}
