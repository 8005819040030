@import 'variables';

.biz-inquiry-index-page {
  &__form {
    margin-bottom: 20px;
    text-align:    right;
  }

  &__select {
    width: 20%;

    @include media-query(xs) {
      width: 100%;
    }
  }
}
