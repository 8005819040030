@import 'variables';

.article-show-picture {
  &__picture {
    display:   block;
    max-width: 100%;
  }

  &__link {
    display:         inline-block;
    margin-top:      5px;
    text-decoration: underline;
  }

  &__caption {
    color:     $gray-color;
    font-size: 14px;
    padding:   5px;
  }
}
