@import "variables";

.admin-facility-support-contents {
  margin: 10px 0;

  &__search {
    display: flex;
    margin:  10px 0;
  }

  &__search-item {
    margin: 0 10px;
  }
}
