@import 'variables';

.biz-inquiry-page {
  &__header {
    margin-bottom: 10px;
  }

  &__section {
    margin-bottom: 30px;
  }
}
