@import 'variables';

.high-grade-facility-brand-card {
  background:    $white;
  border-radius: $base-border-radius;
  display:       flex;
  padding:       15px;

  @include media-query(xs) {
    flex-direction: column;
  }

  &__logo-area {
    align-items:     center;
    display:         flex;
    justify-content: center;
    margin-right:    15px;
    width:           30%;

    @include media-query(xs) {
      height: 60px;
      margin: 0 auto 15px;
      width:  auto;
    }
  }

  &__logo {
    object-fit: contain;
    width:      70%;

    @include media-query(xs) {
      height: 100%;
      width:  100%;
    }
  }

  &__content {
    flex: 1;
  }

  &__name {
    color:         $gray-color;
    margin-bottom: 20px;

    @include media-query(xs) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  &__catchphrase {
    color:         $text-color;
    font-size:     20px;
    font-weight:   700;
    margin-bottom: 20px;

    @include media-query(xs) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
}
