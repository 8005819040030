@import 'variables';

.select-conditions-form {
  background-color: $white;
  border-radius:    10px;

  &__header {
    margin-bottom: 5px;
  }

  &__title {
    background-color:        $primary-color;
    border-top-left-radius:  10px;
    border-top-right-radius: 10px;
    color:                   $white;
    font-size:               20px;
    font-weight:             700;
    padding:                 20px;
    text-align:              center;
  }

  &__conditions-wrapper {
    padding: 20px;
  }

  &__check-box-wrapper {
    display:       flex;
    flex-wrap:     wrap;
    margin-bottom: 15px;
  }

  &__check-box {
    font-size:     14px;
    margin-bottom: 20px;
    width:         50%;
  }

  &__submit {
    display: block;
    margin:  0 auto;
    width:   230px;
  }
}
