@import 'variables';

.article-show-list {
  background-color: $pale-gray-color;
  border-radius:    $base-border-radius;
  list-style:       disc;
  padding:          10px 20px;

  &__item {
    line-height: 1.8;
    list-style:  disc;
    margin-left: 15px;
  }

  &__link {
    text-decoration: underline;
  }
}
