@import 'variables';

.inquiry-page-header {
  align-items:      center;
  background-color: $white;
  display:          flex;
  justify-content:  space-between;
  padding-bottom:   10px;
  padding-top:      10px;
  width:            100%;
  z-index:          10;

  &__logo {
    height: 30px;

    @include media-query(xs) {
      height: 25px;
    }
  }

  &__text {
    font-weight: 700;

    @include media-query(xs) {
      font-size: 14px;
    }
  }
}
