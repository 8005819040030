@import 'variables';

.inquiry-confirm-table {
  width: 100%;

  &__row {
    &:first-of-type th {
      border-top: 1px solid $pale-green-color;
    }

    &:last-of-type th {
      border-bottom: 1px solid $pale-green-color;
    }
  }

  &__key {
    background-color: $pale-green-color;
    border-bottom:    1px solid $white;
    color:            $text-color;
    font-size:        14px;
    padding:          10px;
    vertical-align:   middle;
    width:            80px;
  }

  &__value {
    align-items:      baseline;
    background-color: $white;
    border:           1px solid $pale-gray-color;
    border-left:      0;
    font-size:        14px;
    line-height:      $base-line-height;
    padding:          10px;
  }
}
