@import 'variables';

.recent-facilities-page {
  &__header {
    background-color: $white;
    color:            $text-color;
    padding: 24px max(calc((max(100%, 1000px) - 1000px) / 2), 16px);
  }

  &__header-text {
    font-size:     26px;
    font-weight:   700;
    margin-bottom: 5px;

    @include media-query(xs) {
      font-size: 24px;
    }
  }

  &__small-text {
    font-size:     14px;
    margin-bottom: 3px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__facilities-count {
    color: $primary-color;
  }

  &__facilities-list-area {
    background-color: $pale-gray-color;
    padding:          45px 0;

    @include media-query(xs) {
      padding: 30px 0;
    }
  }

  &__facilities-list {
    display:   flex;
    flex-wrap: wrap;

    @include media-query(xs) {
      display: block;
    }
  }

  &__facility-item {
    background-color: $white;
    border-radius:    8px;
    display:          flex;
    flex-direction:   column;
    height:           200px;
    margin-bottom:    2.5%;
    padding:          25px;
    width:            48.75%;

    @include media-query(xs) {
      margin-bottom: 15px;
      padding:       20px;
      width:         100%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(odd) {
      margin-right: 2.5%;

      @include media-query(xs) {
        margin-right: 0;
      }
    }
  }

  &__facility-name-area {
    align-items:   center;
    display:       flex;
    flex:          1;
    margin-bottom: 15px;
  }

  &__dot {
    color:        $primary-color;
    font-size:    12px;
    margin-right: 3px;
  }

  &__facility-name {
    font-size: 18px;
  }

  &__facility-type {
    font-size:     14px;
    margin-bottom: 10px;
  }

  &__facility-address {
    flex:      1;
    font-size: 14px;
  }

  &__facility-show-button-area {
    align-items:     flex-end;
    display:         flex;
    flex:            2;
    justify-content: center;
    text-align:      center;
  }

  &__facility-show-button {
    align-items:      center;
    background-color: $attention-color;
    border-radius:    15px;
    display:          flex;
    height:           35px;
    justify-content:  center;
    padding:          10px;
    width:            50%;

    @include media-query(xs) {
      width: 70%;
    }
  }

  &__facility-show-button-text {
    width: 70%;
  }

  &__facility-show-button-image {
    padding: 2px 5px 0;
  }
}
