@import 'variables';

.tel-inquiry-block-setting {
  &__big-button-wrapper {
    margin: 10px 0 30px;
  }

  &__big-button {
    border:        1px solid $text-color;
    border-radius: 5px;
    cursor:        pointer;
    margin-bottom: 20px;
    padding:       5px;
    width:         25%;
  }
}
