@import 'variables';

.clipboard-copy-button {
  color:   $primary-color;

  &:hover {
    color: $light-red-color;
  }

  &:active {
    transform: translateY(4px);
  }

  &::after {
    content: '\f0ea';
    font:    normal 600 normal 30px/1 "Font Awesome 5 Free";
  }
}
