@import 'variables';

.facility-type-search-page {
  &__fluid-block {
    background-color: $pale-gray-color;
    margin:           0 -200%;
    padding:          0 200%;
  }

  &__container {
    padding: 0;
  }

  &__side-item {
    margin-bottom: 30px;

    @include media-query(xs) {
      margin-bottom: 30px;
    }
  }

  &__search-item:last-child {
    margin-bottom: 0;
  }

  &__select-condition {
    margin-bottom: 30px;

    @include media-query(xs) {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    padding: 30px 0;
  }

  &__facility-list {
    margin-bottom: 15px;
  }

  &__hr {
    background-color: $pale-gray-color;
    border:           0;
    height:           1px;
    margin:           0;
  }

  &__error {
    background-color: $white;
    border:           2px solid;
    border-radius:    $base-border-radius;
    color:            $red;
    font-size:        18px;
    font-weight:      700;
    padding:          10px;
    width:            100%;
  }

  &__inbound-banner {
    margin-bottom: 15px;
    width: 100%;
  }
}
