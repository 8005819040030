@import 'variables';

.search-submit {
  align-items:      center;
  background-color: $text-color-opacity;
  bottom:           -100px;
  padding:          10px 0;
  position:         fixed;
  right:            0;
  text-align:       center;
  width:            100%;
  z-index:          100;

  &__button-wrapper {
    display:         flex;
    flex-direction:  row-reverse;
    justify-content: space-between;

    @include media-query(sm) {
      width: 80%;
    }

    @include media-query(md) {
      width: 70%;
    }

    @include media-query(lg) {
      width: 70%;
    }
  }

  &__count {
    color:      $white;
    flex:       1;
    font-size:  23px;
    margin:     auto 0;
    text-align: center;

    @include media-query(xs) {
      font-size: 18px;
    }
  }

  &__button {
    width: 35%;

    @include media-query(xs) {
      margin-right: 15px;
      width:        60%;
    }
  }

  &__animate {
    &--on {
      transform:  translateY(-100px);
      transition: transform 0.3s ease-out;
    }

    &__off {
      transform:  translateY(100px);
      transition: transform 0.3s ease-out;
    }
  }
}
