@import 'variables';

.yellow-border-button {
  border:          3px solid $attention-color;
  border-radius:   25px;
  color:           $text-color;
  display:         flex;
  flex-direction:  column;
  font-size:       16px;
  font-weight:     700;
  justify-content: center;
  line-height:     1;
  padding:         6px 30px;
  position:        relative;
  text-align:      center;

  &__right-icon {
    font-size:   20px;
    font-weight: 900;
    position:    absolute;
    right:       8px;
    top:         4px;
  }

  &__left-icon {
    font-size:   20px;
    font-weight: 900;
    left:        8px;
    position:    absolute;
    top:         4px;
  }
}
