@import 'variables';

.scroll-top-button {
  background-color: $attention-color;
  border-radius: $base-border-radius;
  display: inline-block;
  padding: 15px;
  text-align: center;

  @include media-query(xs) {
    border-radius: 0;
    display: block;
  }
}
