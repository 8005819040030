@import 'variables';

.article-banner-mail-magazine {
  display:         flex;
  justify-content: center;

  &__image {
    display: block;
    width:   100%;

    @include media-query(xs) {
      margin: 0 auto;
    }
  }

  &__link {
    display: block;
  }
}
