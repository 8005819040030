@import 'variables';

.article-banner-facility-type-top {
  display:         flex;
  justify-content: space-between;
  width:           100%;

  @include media-query(xs) {
    display: block;
  }

  @include media-query(sm) {
    flex-direction: column;
  }

  %__banner-style {
    border-radius: 10px;
    width:         100%;

    @include media-query(xs) {
      display:       block;
      margin-bottom: 10px;
    }
  }

  &__ikou {
    @extend %__banner-style;
    border: 3px solid $primary-color;
  }

  &__keizoku-a {
    @extend %__banner-style;
    border: 3px solid $light-blue;
  }

  &__keizoku-b {
    @extend %__banner-style;
    border: 3px solid $light-bitter-orange;
  }

  &__link {
    width: 33%;

    @include media-query(sm) {
      width: 100%;
    }
  }
}
