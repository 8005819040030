.recruitment-lps-footer {
  background-color: #303030;
  border:           10px solid  #303030;
  width:            100%;

  &__copyright {
    color:      $white;
    font-size:  15px;
    padding:    15px 0;
    text-align: center;

    @include media-query(xs) {
      font-size: 10px;
      padding:   15px 23px;
    }
  }
}
