@import 'variables';

.area-select-facility {
  display:         flex;
  justify-content: center;

  @include media-query(xs) {
    flex-wrap:       wrap;
  }

  @include media-query(sm) {
    flex-wrap:       wrap;
  }

  &__button {
    background-color: $white;
    border:           2px solid $primary-color;
    border-radius:    $base-border-radius;
    color:            $text-color;
    cursor:           pointer;
    font-size:        16px;
    font-weight:      bold;
    margin-bottom:    10px;
    margin-right:     10px;
    padding:          30px;
    position:         relative;
    text-align:       center;
    width:            calc(100% / 6);

    @include media-query(xs) {
      font-size: 18px;
      padding:   15px;
      width:     45%;
    }

    @include media-query(sm) {
      font-size: 18px;
      padding:   15px;
      width:     45%;
    }

    &::after {
      border-color: transparent $attention-color $attention-color transparent;
      border-style: solid;
      border-width: 10px;
      bottom:       0;
      content:      '';
      position:     absolute;
      right:        0;
    }
  }
}
