@import 'variables';

.link-button {
  border:        1px solid $gray-color;
  border-radius: 4px;
  font-size:     18px;
  margin-right:  10px;
  padding:       5px 15px;

  &--disabled {
    border:        1px solid $light-gray-color;
    border-radius: 4px;
    color:         $light-gray-color;
    font-size:     18px;
    margin-right:  10px;
    padding:       5px 15px;
  }
}
