@import 'variables';

.basic-table {
  width: 100%;

  &__row {
    &:first-of-type th {
      border-top: 2px solid $primary-color;
    }

    &:last-of-type th {
      border-bottom: 2px solid $primary-color;
    }
  }

  &__key {
    background-color: $primary-color;
    border-bottom:    2px solid $white;
    color:            $white;
    font-size:        14px;
    font-weight:      bold;
    padding:          20px 10px;
    vertical-align:   middle;
    width:            15%;

    @include media-query(xs) {
      width: 25%;
    }
  }

  &__value {
    align-items:      baseline;
    background-color: $white;
    border:           2px solid $light-gray-color;
    border-left:      0;
    line-height:      $base-line-height;
    padding:          20px 25px;
  }

  &__text {
    margin-bottom: 15px;

    @include media-query(xs) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__tel-button {
    background-color: $attention-color;
    border-radius: $base-border-radius;
  }

  &__tel-button-content {
    display:         flex;
    justify-content: center;
    padding:         15px;
  }

  &__tel-icon-wrapper {
    margin-right: 5px;
    padding-top:  3px;
  }

  &__tel-icon {
    width: 28px;
  }

  &__tel-button-text {
    font-weight: 700;
    text-align:  center;
  }

  &__tel-button-number {
    font-size:   12px;
    font-weight: 700;
    text-align:  center;
  }

  &__link {
    color:           $text-color;
    text-decoration: underline;
  }
}
