@import 'variables';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-query(sm) {
    height: 81px;
  }

  @include media-query(md) {
    height: 81px;
  }

  @include media-query(lg) {
    height: 81px;
  }

  &__logo-area {
    @include media-query(sm) {
      padding: 0;
    }

    @include media-query(md) {
      padding: 0;
    }

    @include media-query(lg) {
      padding: 0;
    }
  }

  &__logo {
    height: 40px;

    @include media-query(xs) {
      height: 30px;
      margin-bottom: 6px;
    }
  }

  %__agent-logo {
    height: 32px;
  }

  &__agent-logo {
    @extend %__agent-logo;

    &--sp {
      @extend %__agent-logo;
      margin-bottom: 6px;
    }
  }

  &__icons-box {
    display: flex;
  }

  &__icon-wrapper {
    padding: 10px 0 10px 20px;
  }

  &__icon {
    display: block;
    height: 24px;
    margin: 0 auto 2px;
  }

  &__icon-text {
    font-size: 14px;

    @include media-query(xs) {
      font-size: 12px;
    }
  }

  &__fixed {
    // Topページの構成を変更して、sp版もstickyに変更したい
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    @include media-query(desktop) {
      left: 0;
      position: sticky;
      right: 0;
      top: 0;
      z-index: 1000;
    }
  }

  &__after-content {
    margin-top: 70px;

    @include media-query(desktop) {
        margin-top: 0;
    }
  }
}
