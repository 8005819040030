@import 'variables';

.biz-see-more {
  align-items:     center;
  display:         flex;
  font-size:       18px;
  justify-content: flex-end;
  padding:         10px 0;

  &__link {
    color:           $text-color;
    text-decoration: underline;
  }

  &__arrow {
    font-size:    12px;
    padding-left: 5px;
  }
}
