@import 'variables';

.picture-stock-selector {
  display:        flex;
  flex-direction: column;
  height:         100%;
  width:          100%;

  &__attention {
    color:         $light-red-color;
    font-weight:   700;
    margin-bottom: 30px;
    margin-top:    10px;
    text-align:    center;

    @include media-query(xs) {
      font-size:     12px;
      margin-bottom: 15px;
      margin-top:    0;
    }
  }
}
