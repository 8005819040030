@import 'variables';

.read-more {
  text-align: right;

  &__link {
    color: $attention-color;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
  }
}
