@import 'variables';

.biz-not-input-warning-message {
  border:        1px solid $gray-color;
  border-radius: $base-border-radius;
  color:         $gray-color;
  font-weight:   700;
  padding:       10px;

  &__text {
    line-height: $large-line-height;
    list-style:  disc inside;
  }
}
