@import 'variables';

.biz-blog-article-index-page {
  &__new-button {
    display:       inline-block;
    margin-bottom: 20px;
  }

  &__blog-article-list {
    margin-bottom: 20px;
  }

  &__article-card {
    border-bottom: 2px solid $light-gray-color;
    display:       flex;
    padding:       15px;

    &:first-of-type {
      border-top: 2px solid $light-gray-color;
    }
  }

  &__title {
    font-size:     18px;
    font-weight:   700;
    margin-bottom: 10px;
  }

  &__title-area {
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
  }

  &__alert {
    color: $red;
  }
}
