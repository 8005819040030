@import 'variables';

.facility-minimum-info {
  align-items: center;
  display:     flex;
  width:       100%;

  &__label {
    color:        $text-color;
    font-size:    10px;
    font-weight:  bold;
    margin-right: 10px;
    text-align:   left;

    @include media-query(xs) {
      margin-right: 10px;
      width:        11%;
    }
  }

  &__label-text {
    text-align: center;
  }

  &__icon {
    width: 37px;
  }

  &__icon-text {
    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__value {
    flex: 1;
  }

  &__highlight-num {
    color:     $primary-color;
    font-size: 40px;
    margin: 0 10px;

    @include media-query(xs) {
      font-size: 30px;
    }
  }

  &__text {
    font-size:  12px;
    margin-top: 3px;
    text-align: center;
  }
}
