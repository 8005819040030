.contact-inbound-banner {
  display:         flex;
  justify-content: space-between;
  width:           100%;

  @include media-query(xs) {
    display: block;
  }

  &__link {
    width:  49%;
  }

  &__image {
    width:  100%;

    @include media-query(xs) {
      margin: 15px auto;
      width:  100%;
    }
  }
}
