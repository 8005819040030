@import 'variables';

.facility-card {
  background-color: $white;
  border-radius:    10px;

  &__blog-count-wrapper {
    color:         $text-color;
    font-size:     16px;
    margin-bottom: 10px;
  }

  &__blog-count {
    color:       $primary-color;
    font-size:   24px;
    font-weight: 700;
  }

  &__blog-new-mark {
    margin-left: 5px;
  }

  &__disabilities-wrapper {
    display:        flex;
    letter-spacing: 1px;
    margin-bottom:  10px;
  }

  &__disabilities-label {
    color:         $primary-color;
    flex-basis:    85px;
    flex-shrink:   0;
    font-weight:   700;
    padding-right: 5px;
  }

  &__disabilities {
    flex-shrink: 1;
  }
}
