@import 'variables';

.facility-support-program-edit {
  &__title-field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }

  &__description-field {
    height: 250px;
    width:  100%;
  }

  &__recommend-text-field {
    display:       block;
    height:        150px;
    margin-bottom: 30px;
    width:         100%;
  }
}
