@import 'variables';

.facility-support-terms {
  border:      2px solid $pale-gray-color;
  line-height: 16px;
  padding:     20px;

  @include media-query(xs) {
    padding: 16px;
  }

  %font-property {
    color:     $gray-color;
    font-size: 11px;
  }

  &__introduction {
    @extend %font-property;
    margin-bottom: 14px;
  }

  &__text {
    @extend %font-property;
  }
}
