@import 'variables';

.administer-bill-show-page {
  &__link {
    display:       inline-block;
    margin-bottom: 20px;
  }

  &__text-field {
    width: 100%;
  }
}
