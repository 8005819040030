@import 'variables';

.editor-reference-input {
  &__forms {
    display: flex;
    padding: 20px;
  }

  &__form {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
    padding:         10px;
  }

  &__remove-button {
    margin:     auto;
    text-align: center;
    width:      10%;
  }

  &__inputs {
    width: 90%;
  }

  &__field {
    width: 70%;
  }

  &__submit-area {
    margin:     10px;
    text-align: center;
  }

  &__submit {
    margin: 10px;
  }

  &__error {
    color:       $red;
    font-size:   14px;
    margin-left: 30%;
  }
}
