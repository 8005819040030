@import 'variables';

.facility-brand-card-appeal-points {
  &__label {
    background:    $light-yellow-color;
    color:         $gray-color;
    margin-bottom: 10px;
    padding:       5px;
  }

  &__item {
    margin-bottom: 10px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__dot {
    color:        $primary-color;
    display:      inline-block;
    margin-right: 5px;
  }
}
