@import 'variables';
// TODO:DraftJS上用。表表示をcontent_stateにすげ替えたらこっちを本物にする
.article-show-header-1-tmp {
  &__text {
    color:       $text-color;
    font-size:   30px;
    font-weight: 700;

    @include media-query(xs) {
      font-size: 24px;
    }
  }

  &__bottom-line {
    border-top: 4px solid $primary-color;
    margin-top: 15px;
    width:      60px;
  }
}
