@import 'variables';

.article-picture-show-page {
  &__picture {
    max-width: 100%;
  }

  &__input {
    border:        1px solid $gray-color;
    border-radius: 4px;
    font-size:     14px;
    padding:       10px;
    width:         100%;
  }
}
