@import 'variables';

.inquiry-text-field {
  align-items:   center;
  border:        1px solid $text-color;
  border-radius: $base-border-radius;
  display:       flex;
  height:        60px;
  padding:       10px;
  width:         100%;

  &__input {
    border:    0;
    flex:      1;
    font-size: 16px;
    height:    100%;
  }

  %label {
    color:     $white;
    font-size: 10px;
    height:    22px;
    padding:   4px;
  }

  &__label {
    &--required {
      @extend %label;
      background: $light-red-color;
    }

    &--optional {
      @extend %label;
      background: $light-gray-color;
    }
  }

  &__completed {
    color:     $primary-color;
    font-size: 22px;
  }

  &__error {
    color:      $light-red-color;
    margin-top: 10px;
  }
}
