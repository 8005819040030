@import 'variables';

.recruitment-lps-header {
  background-color: $primary-color;
  height:           80px;

  @include media-query(xs) {
    height: 50px;
  }

  &__contents {
    align-items:     center;
    display:         flex;
    flex-direction:  column;
    height:          100%;
    justify-content: center;
    padding:         0 15px 0 15px;

    @include media-query(xs) {
      flex-direction:  row;
      justify-content: space-between;
    }
  }

  &__title {
    color:     $white;
    font-size: 14px;

    @include media-query(xs) {
      font-size: 13px;
    }
  }
}
