@import 'variables';

.prefecture-select {
  display:   flex;
  flex-wrap: wrap;
  padding:   20px;

  @include media-query(sm) {
    justify-content: center;
    padding:         10px 0;
  }

  @include media-query(xs) {
    justify-content: center;
    padding:         10px 0;
  }

  &::after {
    content: '';
    margin:  5px;
    width:   44%;
  }

  &__button {
    background-color: $white;
    border:           2px solid $primary-color;
    border-radius:    $base-border-radius;
    color:            $text-color;
    display:          flex;
    flex-direction:   column;
    font-size:        18px;
    font-weight:      bold;
    height:           75px;
    justify-content:  center;
    margin:           5px;
    padding:          15px;
    position:         relative;
    text-align:       center;
    width:            200px;

    @include media-query(sm) {
      height: 65px;
      width:  44%;
    }

    @include media-query(xs) {
      height: 65px;
      width:  44%;
    }

    &__right-area {
      background: $attention-color;
      height:     100%;
      position:   absolute;
      right:      0;
      top:        0;
      width:      30px;

      @include media-query(xs) {
        width: 15px;
      }
    }
  }

  &__arrow {
    display:         flex;
    justify-content: center;
    margin:          0 auto;
    position:        relative;
    top:             40%;
    width:           10px;

    @include media-query(xs) {
      width: 8px;
    }
  }
}
