@import 'variables';

.select-conditions-form-sp {
  background-color: $white;
  padding: 20px 15px;

  &__container {
    align-items: center;
    display: flex;
  }

  &__icon {
    color: $primary-color;
    font-size: 25px;
    height: 35px;
    margin-right: 5px;
    vertical-align: middle;
    width: 28px;
  }

  &__text-block {
    flex: auto;
    padding: 5px;
  }

  &__search-icon {
    margin-right: 5px;
  }

  a {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
