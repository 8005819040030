@import 'variables';

.notification-modal {
  &__overlay {
    background: $skeleton-gray-color;
    inset:     0;
    position:   fixed;
    z-index:    3;
  }

  &__content {
    background-color: $white;
    border-radius:    $base-border-radius;
    height:           20%;
    left:             40%;
    outline:          none;
    padding:          15px;
    position:         absolute;
    text-align:       center;
    top:              100px;
    width:            20%;
    z-index:          4;
  }

  &__text {
    font-size:   18px;
    padding-top: 20%;
  }

  &__close-button {
    $pc-button-width: 50px;
    background-color: $white;
    border-radius:    50%;
    color:            $text-color;
    font-size:        18px;
    height:           $pc-button-width;
    position:         absolute;
    right:            4px;
    top:              4px;
    width:            $pc-button-width;
    z-index:          10;
  }
}
