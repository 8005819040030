@import 'variables';

.station-index {
  &__title {
    font-size:   20px;
    font-weight: 700;
    margin:      10px;
  }

  &__description {
    margin: 10px 10px 30px;
  }

  &__stations-box {
    margin-left: 30px;
  }
}
