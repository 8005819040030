@import 'variables';

.administer-inquiry-page {
  &__header {
    margin-bottom: 10px;
  }

  &__section {
    margin-bottom: 30px;
  }

  &__radio {
    margin-bottom: 20px;
  }

  &__text-area {
    margin-bottom: 20px;
    width:         100%;
  }

  &__submit {
    text-align: center;
  }
}
