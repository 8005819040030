@import 'variables';

.editorial-department-supervisor-form {
  &__label {
    font-weight:   700;
    margin-bottom: 5px;
  }

  &__file-field {
    margin-bottom: 20px;
  }

  &__text-field {
    margin-bottom: 20px;
    width:         100%;
  }

  &__text-area {
    display:       block;
    height:        150px;
    margin-bottom: 20px;
    resize:        none;
    width:         100%;
  }

  &__text-count {
    text-align: right;
  }
}
