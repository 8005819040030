@import 'variables';

.editor-image-input {
  &__preview {
    width: 60%;
  }

  &__button-area {
    margin-top: 10px;
    text-align: center;
  }

  &__button {
    background-color: $primary-color;
    border:           3px solid $primary-color;
    border-radius:    $base-border-radius;
    display:          block;
    font-weight:      700;
    margin:           0 auto;
    padding:          15px;

    &--uploading {
      background-color: $gray-color;
      border:           3px solid $gray-color;
    }
  }
}
