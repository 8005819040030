@import "variables";

.biz-account-edit-password {
  &__field {
    height: 40px;
    margin-bottom: 20px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__input-area {
    margin-bottom: 50px;
  }

  &__submit {
    text-align: center;
  }
}
