@import 'variables';

.biz-allowed-facility {
  line-height: $large-line-height;

  &__item {
    margin-bottom: 30px;
  }

  &__link {
    color:           $text-color;
    display:         block;
    font-weight:     bold;
    text-decoration: underline;
  }

  &__facility-name {
    color:       $text-color;
    display:     block;
    font-weight: bold;
  }

  &__edit-link-list {
    list-style: disc inside;
    margin-top: 7px;
  }

  &__edit-link {
    list-style:      disc inside;
    text-decoration: underline;

    &:not(:last-of-type) {
      margin-bottom: 7px;
    }
  }

  &__warning {
    align-items:  center;
    display:      inline-flex;
    padding-left: 5px;
  }

  &__warning-icon {
    color:        $light-red-color;
    margin-right: 5px;
  }

  &__warning-message {
    color:     $light-red-color;
    font-size: 12px;
  }
}
