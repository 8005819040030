@import 'variables';

.biz-facility-show-page {
  &__section {
    margin-bottom: 30px;
  }

  &__navigation {
    border:        2px solid $text-color;
    border-radius: $base-border-radius;
    padding:       20px;
    text-align:    center;
  }

  &__navigation-text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;
  }

  &__navigation-attention-text {
    color:       $light-red-color;
    font-size:   14px;
    font-weight: 700;
    margin-top:  20px;
  }

  &__navigation-submit {
    margin-top: 20px;
  }
}
