@import 'variables';

.float-banner {
  bottom:       30px;
  float:        right;
  margin-right: 30px;
  position:     sticky;
  position:     -webkit-sticky;
  width:        250px;
  z-index:      100;
}
