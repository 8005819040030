@import 'variables';

.inquiry-rejected-page {
  margin:      0 auto;
  max-width:   500px;
  padding-top: 30px;

  &__text {
    color:         $red;
    margin-bottom: 40px;
  }

  &__list {
    margin: 20px auto 30px;
    width:  100%;
  }

  &__item {
    margin-bottom: 10px;
    text-align:    left;
  }

  &__link {
    text-decoration: underline;
  }

  &__square-link {
    border:        1px solid $gray-color;
    border-radius: 4px;
    font-size:     18px;
    margin:        0 auto;
    padding:       5px 15px;
    width:         140px;
  }
}
