@import 'variables';

.static-page {
  background-color: $white;
  padding-bottom:   15px;
  padding-top:      15px;

  &__header {
    font-size:     18px;
    font-weight:   700;
    margin-bottom: 10px;
  }

  &__paragraph {
    color:         $text-color;
    font-size:     14px;
    line-height:   $base-line-height;
    margin-bottom: 10px;

    &--small {
      font-size: 12px;
    }

    &--align-right {
      text-align: right;
    }
  }

  &__link {
    color:           $text-color;
    text-decoration: underline;
  }
}
