@import 'variables';

.web-inquiry-block-setting-form {
  &__area {
    margin-top: 30px;
  }

  &__field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }

  &__text-field {
    display:       block;
    height:        120px;
    margin-bottom: 50px;
    width:         100%;
  }

  &__submit {
    text-align: center;
  }
}
