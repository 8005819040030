@import 'variables';

.select-cities-page {
  &__header {
    font-size: 24px;
    margin-bottom: 10px;
    padding-top: 30px;

    @include media-query(xs) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  &__checkbox-area {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin-bottom:   20px;
    padding:         10px;

    &::after {
      content: '';
      width:   30%;
    }
  }

  &__checkbox {
    display: flex;
    padding: 10px;
    width:   30%;
  }

  &__label {
    flex: 1;
  }

  &__button {
    margin:     10px 0 50px;
    text-align: center;
  }
}
