@import 'variables';

.article-card {
  background-color: $white;
  border-radius:    $base-border-radius;
  display:          flex;
  flex-direction:   column;
  padding:          15px;

  &__head {
    display:       flex;
    flex:          1;
    margin-bottom: 15px;
  }

  &__picture {
    @include object-fit-cover();
    height:       85px;
    margin-right: 15px;
    width:        85px;
  }

  &__title-wrapper {
    width: 100%;
  }

  &__open-date {
    color:         $gray-color;
    font-size:     14px;
    margin-bottom: 10px;
    width:         100%;
  }

  &__title {
    font-size:   16px;
    font-weight: 700;
    line-height: $base-line-height;
    text-align:  justify;
  }

  &__summary {
    font-size:     15px;
    line-height:   $large-line-height;
    margin-bottom: 15px;
    text-align:    justify;
  }

  &__link {
    display: block;
    margin:  0 auto;
    width:   60%;
  }
}
