@import 'variables';

.biz-sidebar {
  border-right: 2px solid $pale-gray-color;
  padding:      20px 10px 0 0;

  %__menu {
    align-items:   center;
    display:       flex;
    margin-bottom: 25px;
    position:      relative;
  }

  &__menu {
    @extend %__menu;

    &--active {
      @extend %__menu;

      &::before {
        background-color: $primary-color;
        content:          '';
        height:           100%;
        left:             -15px;
        padding:          0 2px;
        position:         absolute;
      }
    }
  }

  %__sub-menu {
    margin-bottom: 25px;
    padding-left:  10px;
  }

  &__sub-menu {
    @extend %__sub-menu;

    &--left-border {
      @extend %__sub-menu;
      border-left: 4px solid $pale-gray-color;
    }
  }

  %__first-item {
    display:   block;
    font-size: 16px;
  }

  &__first-item {
    @extend %__first-item;
    color: $text-color;

    &--active {
      @extend %__first-item;
      color:       $primary-color;
      font-weight: 700;
    }
  }

  %__second-item {
    display:       block;
    font-size:     14px;
    margin-bottom: 20px;
  }

  %__second-item-icon {
    content:      '\f111';
    font:         normal 600 normal 14px/1 "Font Awesome 5 Free";
    margin-left:  -4px;
    margin-right: 5px;
  }

  &__second-item {
    @extend %__second-item;
    color: $text-color;

    &::before {
      @extend %__second-item-icon;
      color: $gray-color;
    }

    &--active {
      @extend %__second-item;
      color:       $primary-color;
      font-weight: 700;

      &::before {
        @extend %__second-item-icon;
      }
    }
  }

  %__third-item {
    display:       block;
    font-size:     14px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__third-item {
    @extend %__third-item;
    color: $text-color;

    &--active {
      @extend %__third-item;
      color:       $primary-color;
      font-weight: 700;
    }

    &--error {
      @extend %__third-item;
      color: $text-color;

      &::after {
        color:        $light-red-color;
        content:      '\f06a';
        font:         normal 600 normal 14px/1 "Font Awesome 5 Free";
        padding-left: 5px;
      }
    }

    &--warning {
      @extend %__third-item;
      color: $text-color;

      &::after {
        color:        $gray-color;
        content:      '\f06a';
        font:         normal 600 normal 14px/1 "Font Awesome 5 Free";
        padding-left: 5px;
      }
    }
  }

  %__icon {
    font-size:    18px;
    margin-right: 5px;
  }

  &__icon {
    @extend %__icon;
    color: $text-color;

    &--active {
      @extend %__icon;
      color: $primary-color;
    }
  }
}
