@import 'variables';

.article-show-header-2 {
  display:     flex;
  padding:     10px 0;

  &__icon {
    color:        $primary-color;
    font-size:    18px;
    margin-right: 10px;
  }

  &__text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;

    @include media-query(xs) {
      font-size: 18px;
    }
  }
}
