@import 'variables';

.facility-support-operation-tools {
  &__title {
    height: 150px;
    width: 100%;
  }

  &__description {
    height: 200px;
    width: 100%;
  }

  &__articles {
    height: 150px;
    width: 100%;
  }

  &__categories {
    height: 150px;
    width: 270px;
  }
}
