.recruitment-lp-transition {
  &-enter-active {
    transition: opacity 120ms, transform 120ms;
    transition-timing-function: linear;
  }

  &-exit-active {
    transition: opacity 400ms, transform 400ms;
    transition-timing-function: linear;
  }

  .recruitment-lp-transition-next &-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .recruitment-lp-transition-next &-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }

  .recruitment-lp-transition-next &-exit {
    opacity: 1;
    transform: translateX(0%);
  }

  .recruitment-lp-transition-next &-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }

  .recruitment-lp-transition-prev &-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  .recruitment-lp-transition-prev &-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }

  .recruitment-lp-transition-prev &-exit {
    opacity: 1;
    transform: translateX(0%);
  }

  .recruitment-lp-transition-prev &-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
}
