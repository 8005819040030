@import 'variables';

.article-footer-to-b-banner-list {
  &__facility {
    padding: 50px 0;
  }

  &__recruitment {
    padding-bottom: 50px;
  }
}
