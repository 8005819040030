@import 'variables';

.picture-selector {
  display:        flex;
  flex:           1;
  flex-direction: column;
  min-height:     20vh;

  &__pictures {
    border:             1px solid $light-gray-color;
    display:            flex;
    flex-wrap:          wrap;
    margin-bottom:      15px;
    overflow:           auto;
    -ms-overflow-style: none;
  }

  %__picture {
    width: 20%;

    @include media-query(xs) {
      width: 50%;
    }
  }

  &__picture-wrapper {
    @extend %__picture;
    border: 3px solid transparent;

    &--selected {
      @extend %__picture;
      border: 3px solid $attention-color;
    }
  }

  &__picture {
    @include object-fit-cover();
    height: 100%;
    width:  100%;
  }

  &__submit {
    background-color: $primary-color;
    border-radius:    $base-border-radius;
    color:            $white;
    display:          block;
    font-weight:      700;
    margin:           0 auto;
    padding:          15px;
  }
}
