@import 'variables';

.blog-article-editor {
  background: $white;
  border:     1px solid $light-gray-color;
  box-shadow: inset 0 1px 8px -3px $light-gray-color;
  box-sizing: border-box;
  cursor:     text;
  min-height: 150px;
  padding:    15px;
  position:   relative;
}
