@import 'variables';

$pc-body-width: 780px;

.facility-brand-index {
  margin: 0 auto;
  width:  $pc-body-width;

  @include media-query(xs) {
    width: 100%;
  }

  &__banner {
    height:        0;
    margin-bottom: 30px;
    overflow:      hidden;
    padding-top:   56.25%;
    position:      relative;

    @include media-query(xs) {
      margin-bottom: 15px;
    }
  }

  &__banner-text {
    align-items:     center;
    color:           $white;
    display:         flex;
    font-size:       34px;
    font-weight:     700;
    height:          100%;
    justify-content: center;
    left:            0;
    position:        absolute;
    top:             0;
    width:           100%;

    @include media-query(xs) {
      font-size: 24px;
    }
  }

  &__banner-image {
    @include object-fit-cover();
    height:   100%;
    left:     0;
    position: absolute;
    top:      0;
    width:    100%;
  }

  &__description {
    line-height:   $large-line-height;
    margin-bottom: 30px;

    @include media-query(xs) {
      margin-bottom: 15px;
    }
  }

  &__fluid-block {
    background: $pale-gray-color;
  }

  %brand-list {
    margin:      0 auto;
    padding-top: 20px;
    width:       $pc-body-width;

    @include media-query(xs) {
      padding-top: 15px;
      width:       100%;
    }
  }

  &__high-grade-list {
    @extend %brand-list;
    display: block;
  }

  &__low-grade-list {
    @extend %brand-list;
    display:        flex;
    flex-wrap:      wrap;
    padding-bottom: 20px;

    @include media-query(xs) {
      padding-bottom: 15px;
    }
  }

  &__high-grade-card {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-query(xs) {
      margin-bottom: 15px;
    }
  }

  &__low-grade-card {
    margin-bottom: 10px;
    padding-right: 10px;
    width:         calc(100% / 3);

    &:nth-of-type(3n) {
      padding-right: 0;
    }

    @include media-query(xs) {
      margin-bottom: 10px;
      width:         50%;

      &:nth-of-type(3n) {
        padding-right: 10px;
      }

      &:nth-of-type(even) {
        padding-right: 0;
      }
    }
  }
}
