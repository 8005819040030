@import 'variables';

.admin-client-announcement-form {
  &__text-field {
    margin-bottom: 20px;
    width:         100%;
  }

  &__text-area {
    height:        200px;
    margin-bottom: 20px;
    width:         100%;
  }

  &__checkbox {
    margin-bottom: 20px;
  }

  &__submit {
    margin-top: 20px;
    text-align: center;
  }
}
