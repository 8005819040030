@import 'variables';

.search-result-header {
  background-color: $white;
  padding:          20px 0;

  &__title {
    color:         $text-color;
    font-size:     24px;
    font-weight:   900;
    margin-bottom: 8px;

    @include media-query(xs) {
      font-size: 17px;
    }
  }

  &__green-title {
    color: $primary-color;
  }

  &__search-result-information {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
  }

  &__total-count {
    color:       $primary-color;
    font-weight: 700;
  }

  &__button-text {
    color: $text-color;
  }

  &__select-distance-title {
    font-weight:  700;
    padding-left: 7px;
  }

  &__select-distance-buttons {
    display:    flex;
    margin-top: 15px;
  }

  &__select-distance-button {
    background: none;
    border:     none;
    color:      $primary-color;
    cursor:     pointer;
    font-size:  16px;

    &--selected {
      color:       $text-color;
      font-size:   16px;
      font-weight: 700;
      padding:     0 7px;
    }

    &--disabled {
      color:     $gray-color;
      font-size: 16px;
      padding:   0 7px;
    }
  }

  &__select-distance-button-wrap {
    border-right: 2px solid $light-gray-color;

    &:last-child {
      border-right: none;
    }
  }

  &__select-distance {
    @include media-query(sm) {
      margin-top: 15px;
    }

    @include media-query(xs) {
      margin-top: 15px;
    }
  }
}
