@import 'variables';

.inquiry-page {
  margin:  0 auto;
  padding: 50px 0;
  width:   100%;

  @include media-query(xs) {
    padding: 20px 0;
  }

  &__section {
    margin-bottom: 15px;
  }

  &__navigation-text {
    text-align: center;
  }

  &__sub-text {
    font-size:  12px;
    margin-top: 10px;
  }

  &__attention {
    color:       $light-red-color;
    font-size:   16px;
    font-weight: 700;
  }
}
