@import 'variables';

.accounts-search {
  &__form {
    display: flex;
  }

  &__item {
    margin: 10px 20px 10px 0;
  }
}
