@import 'variables';

.date-time-field {
  border:        1px solid $gray-color;
  border-radius: $base-border-radius;
  font-size:     16px;
  padding:       10px;

  &::placeholder {
    color: $light-gray-color;
  }
}
