@import "variables";

.announcements {
  &__section {
    margin-bottom: 100px;

    @include media-query(xs) {
      margin-bottom: 68px;
    }
  }

  &__see-more-button {
    margin: 0 auto;
    width:  200px;
  }

  &__contact-info-table {
    border:        2px solid $pale-gray-color;
    margin-bottom: 40px;
  }

  &__contact-info-table-row {
    border-bottom: 2px solid $pale-gray-color;
    display:       flex;
    padding:       12px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__other-info {
    color:     $gray-color;
    font-size: 12px;
  }
}
