@import 'variables';

.inquiry-purpose-form-pc {
  float:    right;
  margin:   0;
  position: -webkit-sticky;
  position: sticky;
  right:    0;
  top:      0;
  z-index:  10;
}
