@import 'variables';

.article-banners {
  &__mail-magazine {
    margin-bottom: 15px;

    @include media-query(xs) {
      margin-bottom: 20px;
    }
  }
}
