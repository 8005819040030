@import "variables";

.biz-account-config {
  margin-top: -10px;

  &__header {
    align-items: center;
    background-color: $pale-green-color;
    display: flex;
    font-size: 30px;
    font-weight: 700;
    height: 100px;
    margin-bottom: 80px;
    width: 100%;

    @include media-query(xs) {
      font-size: 24px;
      justify-content: center;
    }
  }

  &__header-text {
    margin: 0 auto;
    width: 1000px;

    @include media-query(xs) {
      text-align: center;
    }

    @include media-query(sm) {
      margin: 0;
      padding: 0 12px;
    }
  }

  &__section {
    margin: 0 auto;
    max-width: 700px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__field {
    display: block;
    height: 40px;
    margin-bottom: 40px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 80px;
    }
  }

  &__submit {
    background-color: $attention-color;
    border: 1px solid $attention-color;
    border-radius: 28px;
    display: block;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    width: 350px;

    @include media-query(xs) {
      width: 200px;
    }
  }
}
