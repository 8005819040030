.biz-edit-facility-contract-display-flag-page {
  &__input {
    margin-bottom: 20px;
  }

  &__input-list {
    margin-bottom: 30px;
  }

  &__submit {
    text-align: center;
  }
}
