@import 'variables';

.tag {
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: $base-border-radius;
  display: inline-block;
  padding: 10px;

  &__name {
    color: $white;
  }
}
