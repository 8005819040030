@import "variables";

.login-header {
  align-items:   center;
  border-bottom: 2px solid $pale-gray-color;
  border-top:    5px solid $primary-color;
  padding:       10px;

  &__logo {
    height:       35px;
  }
}
