@import 'variables';

.pagination {
  align-items:     center;
  display:         flex;
  justify-content: space-between;
  margin:          0 auto;
  padding:         15px 0;
  width:           60%;

  @include media-query(xs) {
    width: 100%;
  }

  &__button-wrapper {
    height: 40px;
    width:  40px;
  }

  %__button {
    background-color: $attention-color;
    border-radius:    50%;
    padding:          10px;
    text-align:       center;
  }

  &__button {
    @extend %__button;
    cursor:  pointer;
    display: block;

    &--disable {
      @extend %__button;
      display: none;
    }
  }

  &__arrow {
    height: 18px;
  }

  &__page {
    border-bottom: 1px solid $text-color;
    cursor:        pointer;
    display:       inline-block;
    text-align:    center;
  }

  &__current-page {
    background:    $white;
    border-bottom: 0;
    border-radius: 50%;
    height:        40px;
    padding-top:   calc(18px / 2);
    text-align:    center;
    width:         40px;
  }

  &__page-text {
    color:       $text-color;
    font-size:   18px;
    font-weight: 700;
  }
}
