@import 'variables';

.share-facility {
  &__title {
    font-size:     20px;
    font-weight:   700;
    margin-bottom: 20px;
    text-align:    center;
  }

  &__button-wrapper {
    display:         flex;

    @include media-query(xs) {
      justify-content: flex-end;
    }
  }

  &__button {
    align-items:     center;
    border:          3px solid $attention-color;
    border-radius:   40px;
    color:           $text-color;
    display:         flex;
    font-weight:     700;
    height:          40px;
    justify-content: center;
    padding:         10px;
    width:           100%;
  }

  &__button-icon {
    margin-right: 5px;
    width:        20px;
  }

  &__button-link {
    align-items:     center;
    display:         flex;
    font-size: 14px;
    justify-content: center;
    margin-right: 5px;
    width:           45%;
  }
}
