@import 'variables';

.user-flash {
  padding: 20px 15px;

  %message {
    background-color: $white;
    border:           2px solid;
    border-radius:    $base-border-radius;
    font-size:        18px;
    font-weight:      700;
    padding:          10px;
    width:            100%;
  }

  &__message {
    &--success {
      @extend %message;
      color: $thick-green-color;

      &::before {
        content: '\f058 ';
        font:    normal 600 normal 20px/1 "Font Awesome 5 Free";
      }
    }

    &--error {
      @extend %message;
      color: $red;

      &::before {
        content: '\f06a ';
        font:    normal 600 normal 20px/1 "Font Awesome 5 Free";
      }
    }
  }
}
