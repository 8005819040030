@import 'variables';

.recommended-facilities {
  background-color: $pale-gray-color;
  padding:          30px 0 45px;

  @include media-query(xs) {
    padding-bottom: 50px;
  }

  &__header {
    padding-bottom: 30px;

    @include media-query(xs) {
      padding-bottom: 10px;
    }
  }

  &__wrapper {
    margin: 0 auto;
    width:  780px;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__items {
    display:       flex;
    flex-wrap:     wrap;
    margin-bottom: 30px;

    @include media-query(xs) {
      display: block;
    }
  }

  &__item-wrapper {
    display:        flex;
    flex-direction: column;
    margin-bottom:  30px;
    width:          50%;

    @include media-query(xs) {
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:nth-of-type(even) {
      padding-left: 10px;

      @include media-query(xs) {
        padding: 0;
      }
    }

    &:nth-of-type(odd) {
      padding-right: 10px;

      @include media-query(xs) {
        padding: 0;
      }
    }
  }

  &__item {
    flex: 1 1 auto;
  }

  &__more-button {
    align-items:     center;
    background:      $attention-color;
    border-radius:   $base-border-radius;
    box-shadow:      0 2px 4px $shadow-color;
    display:         flex;
    justify-content: center;
    margin:          0 auto;
    padding:         20px 15px;
    width:           60%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__more-button-text {
    font-size:    18px;
    font-weight:  700;
    margin-right: 15px;

    @include media-query(xs) {
      font-size: 16px;
    }
  }
}
