@import 'variables';

.administer-facility-show {
  &__geocoding-form {
    width: 100%;
  }

  &__geocoding-field {
    margin-bottom: 10px;
    width:         100%;
  }

  &__geocoding-button {
    background-color: $primary-color;
    border-radius:    4px;
    color:            $white;
    cursor:           pointer;
    font-size:        16px;
  }

  &__base-facility-area {
    margin-bottom: 20px;
  }
}
