@import 'variables';

.login-form {
  padding: 15px 0;

  &__text-field {
    font-size:     16px;
    margin-bottom: 20px;
    width:         100%;
  }

  &__submit-wrapper {
    margin-top: 10px;
    text-align: center;
  }

  &__submit {
    width: 40%;

    @include media-query(xs) {
      width: 90%;
    }
  }

  &__link-wrapper {
    text-align: center;
  }

  &__link {
    display:         inline-block;
    margin-top:      40px;
    text-decoration: underline;
  }

  &__notice {
    margin-bottom: 10px;
  }
}
