@import "variables";

.biz-account-edit-email {
  &__text {
    margin-bottom: 20px;
  }

  &__field {
    display: block;
    height: 40px;
    margin-bottom: 50px;
    width: 100%;
  }

  &__submit {
    text-align: center;
  }
}
