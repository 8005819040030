@import 'variables';

.footer-link-list {
  padding: 0 15px;

  @include media-query(xs) {
    padding-left: 15px;
  }

  @include media-query(sm) {
    padding-left: 15px;
  }

  &__section {
    margin-bottom: 10px;
  }

  &__link {
    @extend %link;
    display: inline-block;
    margin:  0 10px 10px 0;
  }

  &__deactivate-link {
    color:   $gray-color;
    display: inline-block;
    margin:  0 10px 10px 0;
  }
}
