@import 'variables';

.inquiry-fixed-row-button {
  background-color: $white;
  bottom:           0;
  box-shadow:       0 1px 4px 1px rgba(0, 0, 0, 0.14);
  display:          flex;
  padding:          10px 4px;
  position:         fixed;
  right:            0;
  width:            100%;
  z-index:          1;

  &__list {
    display:         flex;
    justify-content: center;
    width:           100%;
  }

  &__text {
    color:         $text-color;
    font-size:     12px;
    font-weight:   700;
    line-height:   14px;
    margin-bottom: 10px;
    text-align:    center;
  }

  &__sp-area {
    align-items: center;
    width:       50%;
  }

  &__sp-area--j {
    align-items: center;
    width:       62%;
  }

  %button {
    align-items:     center;
    border-radius:   4px;
    color:           $text-color;
    display:         flex;
    height:          50px;
    justify-content: center;
    margin:          0 auto;
  }

  &__tel-button {
    @extend %button;
    background-color: $attention-color;
    width:            90%;
  }

  &__web-button {
    @extend %button;
    border:       2px solid $attention-color;
    margin-right: 5px;
    width:        90%;

    &--only {
      @extend %button;
      background-color: $attention-color;
      margin:           0;
    }
  }

  &__text-wrapper {
    align-items: center;
    display:     flex;
    padding:     7px;
  }

  &__sp-inquiry-icon {
    height:       23px;
    margin-right: 7px;
  }

  &__sp-text {
    color: $text-color;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: auto 0;
    margin-right: 20px;
  }

  &__sp-text-circle {
    margin-right: 5px;

    &::after {
      border: 2px solid #e94d40;
      border-radius: 50%;
      content: "";
      display: block;
      height: 12px;
      width: 12px;
    }
  }

  &__button-text {
    font-size:   20px;
    font-weight: 700;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__arrow-icon {
    height:      15px;
    margin-left: 7px;
  }
}
