@import 'variables';

.select-cities-page-xs {
  &__header {
    background-color: $pale-gray-color;
    font-weight:      700;
    padding:          20px 15px;
    width:            100%;
  }

  &__check-box-wrapper {
    border-bottom: $primary-color dashed 1px;
    display:       flex;
    padding:       25px 15px;

    &:first-of-type {
      border-top: $primary-color dashed 1px;
    }
  }

  &__check-box {
    flex: 1;
  }
}
