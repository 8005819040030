@import 'variables';

.breadcrumb-facility {
  background-color: $white-gray-color;
  padding: 10px 0;

  @include media-query(xs) {
    background-color: transparent;
    padding: 8px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: -8px;
    margin-top: -8px;
  }

  &__links {
    color:     $gray-color;
    font-size: 14px;
    line-height: 21px;
    width:     100%;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .current {
      color: #333;
    }

    @include media-query(xs) {
      font-size: 12px;
      line-height: 21px;
      margin: -2px 0;
      width:     100%;
  
      a {
        text-decoration: underline;
      }
    }
  }

  &--bottom {
    margin-bottom: 16px;

    @include media-query(xs) {
      margin-bottom: 0;
    }
  }
}
