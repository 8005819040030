@import 'variables';

%list {
  padding: 15px;
}

%list__item {
  list-style: none;
  margin-bottom: 15px;
}
