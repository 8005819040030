@import 'variables';

.account-activation-progress-bar {
  display: flex;

  %stage {
    font-size:   17px;
    font-weight: 700;
    padding:     10px 5px;
    position:    relative;
    text-align:  center;
    width:       calc(100% / 3);
  }

  &__stage {
    @extend %stage;
    background-color: $pale-green-color;

    &--active {
      @extend %stage;
      background-color: $light-green-color;
      z-index:          2;

      &:not(:last-of-type)::after {
        $border-width: 21px;
        border:            solid $border-width transparent;
        border-left-color: $light-green-color;
        content:           '';
        position:          absolute;
        right:             -#{2 * $border-width};
        top:               calc(50% - #{$border-width});
      }
    }
  }
}
