@import 'variables';

.supervisor {
  border:  2px solid $light-gray-color;
  display: flex;
  padding: 20px;

  @include media-query(xs) {
    flex-direction: column;
  }

  &__top {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  &__picture {
    @include object-fit-cover();
    height:       120px;
    margin-right: 20px;
    width:        120px;
  }

  &__text {
    flex:      1;
    word-wrap: break-word;
  }

  &__subtext {
    font-size: 14px;
    margin:    12px 0;
  }

  &__name {
    color:         $text-color;
    font-size:     18px;
    font-weight:   700;
    margin-bottom: 10px;
  }

  &__introduction {
    color:       $text-color;
    font-size:   16px;
    line-height: $large-line-height;
  }
}
