@import 'variables';

.search-top-header {
  font-size:   24px;
  font-weight: 700;

  @include media-query(xs) {
    font-size: 20px;
  }
}
