@use 'sass:math';
@import 'variables';

.modal {
  &__overlay {
    background: $skeleton-gray-color;
    inset: 0;
    position: fixed;
    z-index: 3;
  }

  &__content {
    background-color: $white;
    border-radius: $base-border-radius;
    inset: 30px 100px;
    outline: none;
    overflow-y: auto;
    padding: 15px;
    position: absolute;
    z-index: 4;

    @include media-query(xs) {
      left: 30px;
      right: 30px;
    }
  }

  &__resume_sp {
    background-color: $white;
    height: 100%;
    outline: none;
    position: absolute;
    z-index: 4;
  }

  &__small {
    background-color: $white;
    border-radius: 8px;
    left: 50%;
    max-height: 567px;
    max-width: 90%;
    outline: none;
    overflow: hidden;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    z-index: 4;
  }

  &__xs {
    background-color: $white;
    border-radius: 8px;
    left: 50%;
    max-height: 567px;
    outline: none;
    overflow: hidden;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    z-index: 4;

    @include media-query(xs) {
      width: calc(100% - 32px);
    }
  }

  &__resume_occupation {
    background-color: $white;
    border-radius: 8px;
    height: 100%;
    left: 50%;
    max-height: 567px;
    max-width: 90%;
    outline: none;
    overflow: scroll;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    z-index: 4;
  }

  &__close-button {
    $pc-button-width: 40px;
    background-color: $light-gray-color;
    border-radius: 50%;
    color: $white;
    font-size: 20px;
    height: $pc-button-width;
    position: absolute;
    right: 5px;
    top: 5px;
    width: $pc-button-width;
    z-index: 10;

    @include media-query(xs) {
      $sp-button-width: 40px;
      font-size: 24px;
      height: $sp-button-width;
      right: -#{math.div($sp-button-width, 2)};
      top: -#{math.div($sp-button-width, 2)};
      width: $sp-button-width;
    }
  }

  &__visit_date_content {
    background-color: $white;
    border-radius: 8px;
    inset: 16px;
    margin: auto;
    max-height: fit-content;
    max-width: 880px;
    overflow-y: auto;
    position: absolute;
    z-index: 4;

    @include media-query(xs) {
      margin: unset;
      max-height: unset;
      max-width: unset;
    }
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Body--fixed {
  position: fixed;
}
