@import 'variables';

.blog-new-mark {
  background-color: $attention-color;
  color:            $text-color;
  display:          inline-block;
  font-size:        12px;
  line-height:      1;
  padding:          3px;
}
