@import 'variables';

.writer-articles-index-page {
  &__title {
    margin-bottom: 20px;
  }

  &__table {
    margin-top: 35px;
  }
}
