@import 'variables';

.accordion {
  position: relative;

  %title {
    border-bottom: 2px solid $light-gray-color;
  }

  %yellow-arrow-before {
    color:    #333;
    content:  "\f111";
    font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
    position: absolute;
    right:    30px;
    top:      15px;

    @include media-query(xs) {
      right: 16px;
    }
  }

  %yellow-arrow-after {
    color:    $attention-color;
    font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
    position: absolute;
    right:    30px;
    top:      14px;

    @include media-query(xs) {
      right: 15px;
    }
  }

  &__title {
    @extend %title;
    cursor:  pointer;
    display: block;
    padding: 15px 40px 15px 30px;
    width:   100%;

    @include media-query(xs) {
      padding: 15px 40px 15px 15px;
    }

    &::before {
      @extend %yellow-arrow-before;
    }

    &::after {
      @extend %yellow-arrow-after;
      content: '\f13a';
    }

    &--on-clicked {
      @extend %title;
      background: $primary-color;
      color:      $white;
      cursor:     pointer;
      display:    block;
      padding:    15px 40px 15px 30px;
      width:      100%;

      &::before {
        @extend %yellow-arrow-before;
      }

      &::after {
        @extend %yellow-arrow-after;
        content: '\f139';
      }
    }
  }

  &__children {
    display: none;

    &--on-clicked {
      border-bottom:   2px solid #d6d6d6;
      display:         flex;
      flex-wrap:       wrap;
      justify-content: space-between;
      padding:         10px 10px 20px;

      &::after {
        content: '';
        width:   30%;
      }
    }
  }
}
