@import 'variables';

.editor-articles-has-article-pictures-page {
  &__item {
    border-bottom:  1px solid $gray-color;
    display:        flex;
    margin-bottom:  15px;
    padding-bottom: 15px;
  }

  &__info {
    margin-left: 15px;
  }

  &__info-item {
    margin-bottom: 10px;
  }
}
