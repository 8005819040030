@import 'variables';

.account-activation-set-password-page {
  &__head {
    margin-bottom: 10px;
    margin-top:    5px;
  }

  &__progress-bar {
    margin-bottom: 10px;
  }
}
