@import 'variables';

.inquiry-text-area {
  border:        1px solid $text-color;
  border-radius: $base-border-radius;
  height:        150px;
  padding:       10px;
  width:         100%;

  &__input-wrapper {
    display: flex;
    height:    100%;
  }

  &__input {
    border:    0;
    flex:      1;
    font-size: 16px;
    resize:    none;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  %label {
    color:     $white;
    font-size: 10px;
    height:    22px;
    padding:   4px;
  }

  &__label {
    &--required {
      @extend %label;
      background: $light-red-color;
    }

    &--optional {
      @extend %label;
      background: $light-gray-color;
    }
  }
}
