@import 'variables';

.blog-article-form {
  &__not-sp {
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px;
  }

  &__header {
    align-items:     center;
    background:      $white;
    border-bottom:   2px solid $pale-gray-color;
    display:         flex;
    justify-content: space-between;
    margin-bottom:   20px;
    padding:         20px 0;
    position:        sticky;
    top:             0;
    z-index:         2;
  }

  &__header-buttons {
    flex:       1;
    text-align: right;
  }

  &__container {
    margin: 0 auto;
    width:  700px;
  }

  &__section {
    margin-bottom: 20px;
  }

  &__label {
    font-weight:   700;
    margin-bottom: 10px;
  }

  &__title {
    border:     1px solid $light-gray-color;
    box-shadow: inset 0 1px 8px -3px $light-gray-color;
    font-size:  16px;
    padding:    15px;
    width:      100%;
  }

  &__top-picture {
    margin: 0 auto;
    width:  80%;
  }

  &__picture {
    height: auto;
    width:  100%;
  }

  %submit {
    border-radius: $base-border-radius;
    margin:        0 15px;
    padding:       10px;
    text-align:    center;
  }

  &__submit {
    @extend %submit;

    &--save {
      @extend %submit;
      background: $white;
      border:     1px solid $primary-color;
      color:      $primary-color;
    }

    &--save-and-open {
      @extend %submit;
      background: $primary-color;
      color:      $white;
    }

    &--save-and-open-disable {
      @extend %submit;
      background: $light-gray-color;
      color:      $white;
    }
  }
}
