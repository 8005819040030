@import 'variables';

.root-searchable-sentence {
  font-weight: 600;

  &__title {
    background-color: $primary-color;
    color: $white;
    font-size: 30px;
    padding: 15px;

    @include media-query(xs) {
      font-size: 18px;
      text-align: center;
    }
  }

  &__content {
    color: $text-color;
    font-size: 18px;
    padding: 15px;

    @include media-query(xs) {
      font-size: 16px;
    }
  }
}
