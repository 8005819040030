@import 'variables';

.DraftEditor-root {
  overflow-y: auto;
  padding: 0 15px;

  @include media-query(xs) {
    left:      50%;
    overflow:  hidden;
    position:  relative;
    transform: translateX(-50%);
    width:     100vw;
  }
}
