@import 'variables';

.no-charging-request-form {
  &__attention {
    color:         $light-red-color;
    margin-bottom: 20px;
  }

  &__text-area {
    margin-bottom: 20px;
    width:         100%;
  }

  &__submit {
    text-align: center;
  }
}
