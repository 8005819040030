@import 'variables';

.tel-inquiry-block-setting-form {
  &__message {
    color:         $red;
    margin-bottom: 15px;
  }

  &__attention-message {
    font-weight:     700;
    text-decoration: underline;
  }

  &__field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }

  &__text-field {
    display:       block;
    height:        120px;
    margin-bottom: 50px;
    width:         100%;
  }

  &__submit {
    text-align: center;
  }
}
