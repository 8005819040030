@import 'variables';

.announcement-page {
  background: $white;
  padding: 10px;

  &__created-at {
    color: $gray-color;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__body {
    color: $text-color;
  }
}
