@import "variables";

.top-for-facility-operation-support-available {
  margin:    0 auto;
  max-width: 1000px;

  &__header {
    background-color: $pale-green-color;
    display:          flex;
    margin-bottom:    80px;

    @include media-query(xs) {
      margin-bottom: 44px;
    }

    @include media-query(sm) {
      font-size: 40px;
    }
  }

  &__header-picture {
    height: 300px;
    margin: 0 auto;
    width:  1200px;

    @include media-query(xs) {
      height: 50vw;
      width:  100%;
    }

    @include media-query(sm) {
      height: 200px;
      width:  400px;
    }
  }

  &__header-wrapper {
    margin-top: -10px;

    @include media-query(xs) {
      width: 100vw;
    }
  }

  &__section {
    margin-bottom: 100px;

    @include media-query(xs) {
      margin-bottom: 68px;
    }
  }

  &__section-title {
    display:         flex;
    justify-content: space-between;

    @include media-query(xs) {
      font-size:   20px;
      font-weight: 700;
      padding:     20px 16px 20px 0;
    }
  }

  &__see-more {
    margin-left: auto;
    padding:     12px 0;
  }

  &__see-more-button {
    margin: 0 auto;
    width:  200px;
  }

  &__contact-info-table {
    border:        2px solid $pale-gray-color;
    margin-bottom: 40px;

    @include media-query(xs) {
      border: none;
    }
  }

  &__contact-info-table-row {
    border-bottom: 2px solid $pale-gray-color;
    display:       flex;
    padding:       12px;

    &:last-of-type {
      border-bottom: none;

      @include media-query(xs) {
        border-bottom: 1px solid $pale-gray-color;
      }
    }

    &--is-read {
      background-color: $shadow-color;
      border-bottom:    2px solid $pale-gray-color;
      display:          flex;
      padding:          12px 12px 12px 12px;

      &:last-of-type {
        border-bottom: none;

        @include media-query(xs) {
          border-bottom: 1px solid $pale-gray-color;
        }
      }
    }

    @include media-query(xs) {
      border: 1px solid $pale-gray-color;
    }
  }

  &__contact-info-table-icon {
    align-items:     center;
    display:         flex;
    font-size:       24px;
    justify-content: center;
    margin-right:    12px;

    .fa-check-circle {
      color: $primary-color;
    }
  }

  &__type {
    font-size:     14px;
    margin-bottom: 8px;
  }

  &__other-info {
    color:     $gray-color;
    font-size: 12px;
  }

  &__image-wrapper {
    display:         flex;
    justify-content: space-between;
    margin-bottom:   100px;

    @include media-query(xs) {
      flex-direction:  column;
      justify-content: center;
      margin-bottom:   45px;
    }

    @include media-query(sm) {
      flex-direction:  column;
      justify-content: center;
      margin-bottom:   45px;
    }
  }

  &__image-link {
    display: block;
    width:   48%;

    @include media-query(xs) {
      width: 100%;
    }

    @include media-query(sm) {
      width: 100%;
    }
  }

  %__link-item {
    align-items:     center;
    background-size: cover;
    color:           $white;
    display:         flex;
    font-size:       40px;
    height:          240px;
    justify-content: center;
    width:           100%;

    @include media-query(xs) {
      font-size:       28px;
      height:          162px;
      margin:          0 auto 20px;
      text-decoration: underline;
      width:           100%;
    }
  }

  &__link-left {
    @extend %__link-item;
    background-image: url("../images/facility_support_link_image_pc.jpg");
    margin-right:     40px;

    &:hover {
      .top-for-facility-operation-support-available__link-left-text {
        border-bottom:  2px solid $white;
        padding-bottom: 2px;

        @include media-query(xs) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

    @include media-query(xs) {
      margin-bottom: 20px;
      margin-right:  0;
    }

    @include media-query(sm) {
      margin: 0 auto 40px;
    }
  }

  &__link-right {
    @extend %__link-item;
    background-image: url("../images/facility_attracting_user_link_pc.jpg");

    &:hover {
      .top-for-facility-operation-support-available__link-right-text {
        border-bottom:  2px solid $white;
        padding-bottom: 2px;

        @include media-query(xs) {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

    @include media-query(sm) {
      margin: 0 auto;
    }
  }
}
