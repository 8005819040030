@import 'variables';

.article-show-quotation {
  background-color: $pale-gray-color;
  border-radius:    $base-border-radius;
  padding:          30px;
  position:         relative;

  &::before {
    content:   url('../images/indent_l.png');
    left:      12px;
    position:  absolute;
    top:       12px;
    transform: scale(0.9);
  }

  &::after {
    bottom:    12px;
    content:   url('../images/indent_r.png');
    position:  absolute;
    right:     12px;
    transform: scale(0.9);
  }

  &__text {
    display:       block;
    line-height:   1.8;
    margin-bottom: 10px;
  }

  &__link-row {
    display: flex;
  }

  &__link {
    display:         inline-block;
    text-decoration: underline;
  }
}
