@import 'variables';

.editor-image-modal-tab {
  &__tab-list {
    border-bottom: 1px solid $text-color;
    margin:        0 0 10px;
    padding:       0;
  }

  &__tab {
    border:                      1px solid transparent;
    border-bottom:               0;
    cursor:                      pointer;
    display:                     inline-block;
    list-style:                  none;
    padding:                     6px 12px;
    position:                    relative;
  }

  &__tab-panel {
    display: block;
  }
}
