@import 'variables';

.web-inquiry-page {
  &__content {
    padding-top: 57px;

    @include media-query(xs) {
      padding-top: 32px;
    }
  }
}
