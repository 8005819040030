@import 'variables';

.select-stations-page {
  &__header {
    font-size:     24px;
    margin-bottom: 10px;
    padding-top:   30px;

    @include media-query(xs) {
      font-size:     20px;
      margin-bottom: 30px;
    }
  }
}
