@import 'variables';

.biz-edit-inquiry-setting-page {
  &__input {
    margin-bottom: 20px;
  }

  &__input-list {
    margin-bottom: 30px;
  }

  &__text-field {
    width: 100%;
  }

  &__submit {
    text-align: center;
  }

  &__attention {
    color:      $light-red-color;
    margin: 20px 0;
  }
}
