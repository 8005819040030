@import 'variables';

%submit {
  border-radius: $base-border-radius;
  font-size:     20px;
  padding:       20px 30px;

  @include media-query(xs) {
    font-size: 16px;
  }
}

.submit {
  @extend %submit;
  background-color: $primary-color;
  box-shadow:       0 2px 4px $shadow-color;
  color:            $white;
  cursor:           pointer;

  &--disabled {
    @extend %submit;
    background-color: $light-gray-color;
    color:            $white;
    cursor:           not-allowed;
  }
}

.to_create {
  @extend %submit;
  background-color: $primary-color;
  box-shadow:       0 2px 4px $shadow-color;
  color:            $white;
  cursor:           pointer;
  display: inline-block;
  margin-bottom: 30px;
}
