@import 'variables';

.biz-input-progress-bar {
  font-weight: 700;
  text-align:  center;

  &__percentage {
    color: $light-red-color;
  }

  &__gray-bar {
    background-color: $light-gray-color;
    border-radius:    $base-border-radius;
    height:           30px;
    margin-top:       10px;
    width:            100%;
  }

  &__green-bar {
    background-color: $primary-color;
    border-radius:    $base-border-radius;
    height:           100%;
  }
}
