@import 'variables';

.facility-support-footer {
  background-color: $primary-color;
  width:            100%;

  &__list {
    display:         flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1000px;
    width: 1170px;

    @include media-query(md) {
      width: 940px;
    }

    @include media-query(sm) {
      padding: 0;
      width: 720px;
    }

    @include media-query(xs) {
      flex-wrap:       wrap;
      justify-content: space-between;
      padding: 0;
      width: 100%;
    }
  }

  &__list-item {
    align-items: center;
    display:     flex;
    padding:     40px 0;
    width:       50%;

    @include media-query(xs) {
      border-bottom: 1px solid $white;
      height:        60px;
      padding:       23px 0 22px 16px;
      width:         100%;
    }
  }

  &__list-item-wrapper {
    background-color: $white;
    display:          flex;
    justify-content:  space-between;
    padding:          18px 0 18px 0;
    width:            100%;

    &:nth-of-type(1) {
      border-right: 1px solid $primary-color;
    }

    @include media-query(xs) {
      background-color: $primary-color;
      display:          flex;
      padding:          0;
      width:            100%;

      &:nth-of-type(1) {
        border-right: 0;
      }
    }
  }

  &__text {
    margin: 0 auto;

    @include media-query(xs) {
      color:     $white;
      flex:      1;
      font-size: 15px;
    }
  }

  &__icon {
    display:  inline-block;
    height:   24px;
    position: relative;
    right:    20px;
    width:    24px;

    &::before {
      color:    $text-color;
      content:  '\f111';
      font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
      left:     2px;
      position: absolute;
      top:      2px;
    }

    &::after {
      color:    $attention-color;
      content:  '\f138';
      font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
      position: absolute;
    }
  }

  &__copyright {
    color:          $white;
    font-size:      15px;
    padding-bottom: 40px;
    text-align:     center;

    @include media-query(xs) {
      background-color: $white;
      color:            $text-color;
      font-size:        10px;
      padding:          15px 23px;
    }
  }

  &__link {
    width: 100%;
  }
}
