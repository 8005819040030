@import 'variables';

.reference-articles {
  @include media-query(sm) {
    background-color: $white;
    border-radius:    10px;
  }

  @include media-query(md) {
    background-color: $white;
    border-radius:    10px;
  }

  @include media-query(lg) {
    background-color: $white;
    border-radius:    10px;
  }

  &__title {
    font-size:   24px;
    font-weight: 700;
    padding:     20px;
    text-align:  center;

    @include media-query(sm) {
      background-color:        $primary-color;
      border-top-left-radius:  10px;
      border-top-right-radius: 10px;
      color:                   $white;
      font-size:               20px;
    }

    @include media-query(md) {
      background-color:        $primary-color;
      border-top-left-radius:  10px;
      border-top-right-radius: 10px;
      color:                   $white;
      font-size:               20px;
    }

    @include media-query(lg) {
      background-color:        $primary-color;
      border-top-left-radius:  10px;
      border-top-right-radius: 10px;
      color:                   $white;
      font-size:               20px;
    }
  }

  &__bottom-line {
    @include media-query(xs) {
      border-top: 5px solid $primary-color;
      margin:     15px auto 0;
      width:      100px;
    }
  }

  &__article-box {
    display: flex;
    padding: 20px;

    @include media-query(xs) {
      padding-left:  0;
      padding-right: 0;
    }
  }

  &__article-left-box {
    margin-right: 15px;
    width:        $pc-image-size;

    @include media-query(xs) {
      width: $sp-image-size;
    }
  }

  &__picture {
    @include object-fit-cover();
    width: 100%;

    @include media-query(xs) {
      height: $sp-image-size;
      width:  $sp-image-size;
    }
  }

  &__article-right-box {
    flex:           1;
    flex-direction: column;
  }

  &__article-title {
    color:       $text-color;
    font-size:   14px;
    font-weight: 700;
  }

  &__read-more {
    color:           $text-color;
    text-align:      right;
    text-decoration: underline;
  }

  &__read-more-icon {
    display:  inline-block;
    height:   24px;
    position: relative;
    width:    24px;

    &::before {
      color:    $text-color;
      content:  '\f111';
      font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
      left:     3px;
      position: absolute;
      top:      7px;
    }

    &::after {
      color:    $attention-color;
      content:  '\f138';
      font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
      left:     1px;
      position: absolute;
      top:      5px;
    }
  }

  &__border-line {
    border:        1px solid $light-gray-color;
    margin-bottom: 20px;

    @include media-query(sm) {
      margin: 0 20px;

      &:last-of-type {
        display: none;
      }
    }

    @include media-query(md) {
      margin: 0 20px;

      &:last-of-type {
        display: none;
      }
    }

    @include media-query(lg) {
      margin: 0 20px;

      &:last-of-type {
        display: none;
      }
    }
  }
}
