@import 'variables';

.radio {
  cursor:       pointer;
  display:      inline-block;
  margin-right: 30px;
  padding-left: 30px;
  position:     relative;

  &::before {
    border:        1px solid $text-color;
    border-radius: 50%;
    content:       '';
    display:       block;
    height:        20px;
    left:          0;
    position:      absolute;
    top:           0;
    width:         20px;
  }

  &__input {
    appearance:       none;
    background-color: $white;
    border-radius:    50%;
    height:           12px;
    left:             2.25px;
    position:         absolute;
    top:              2px;
    width:            12px;

    &:checked {
      background-color: $text-color;
    }

    &:focus {
      outline: 0;
    }
  }
}
