@import 'variables';

.inquiry-checkbox {
  background:    $pale-gray-color;
  border-radius: $base-border-radius;
  padding:       15px;

  &__label {
    width: 100%;
  }
}
