@import 'variables';

.facility-list-search-sp {
  background-color: $white;
  padding:          20px 15px;

  &__container {
    align-items: center;
    display:     flex;
  }

  &__train-icon {
    display:      inline-block;
    height:       32px;
    margin-right: 5px;
    width:        28px;

    &::after {
      color:       $text-color;
      content:     '\f238';
      font:        normal 600 normal 25px/1 "Font Awesome 5 Free";
      margin-left: 3px;
    }
  }

  &__map-icon {
    display:      inline-block;
    height:       32px;
    margin-right: 5px;
    width:        28px;

    &::after {
      color:       $text-color;
      content:     '\f041';
      font:        normal 600 normal 29px/1 "Font Awesome 5 Free";
      margin-left: 5px;
    }
  }

  &__icon {
    color:          $primary-color;
    font-size:      25px;
    height:         35px;
    margin-right:   5px;
    vertical-align: middle;
    width:          28px;
  }

  &__arrow {
    display: inline;
  }

  &__search-icon {
    margin: 0 6px 3px 0;
  }

  &__text-block {
    flex:    auto;
    padding: 5px;
  }
}
