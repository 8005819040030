@import 'variables';

.disabled-submit {
  background-color: $gray-color;
  border:           0;
  border-radius:    $base-border-radius;
  color:            $white;
  font-size:        20px;
  font-weight:      bold;
  padding:          7px;
}
