@import 'variables';

.facility-support-program-form {
  &__area {
    margin-top: 30px;
  }

  &__field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }
}
