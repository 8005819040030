@import 'variables';

.admin-sidebar {
  border-right: 2px solid $pale-gray-color;
  padding:      20px 10px 0 0;

  %__menu {
    align-items:   center;
    display:       flex;
    margin-bottom: 25px;
    position:      relative;
  }

  &__menu {
    @extend %__menu;

    &--active {
      @extend %__menu;
      color:       $primary-color;
      font-weight: 700;

      &::before {
        background-color: $primary-color;
        content:          '';
        height:           100%;
        left:             -15px;
        padding:          0 2px;
        position:         absolute;
      }
    }
  }

  &__item {
    display:   block;
    font-size: 16px;
  }
}
