@import 'variables';

.facility-support-footer-wrapper {
  margin: 0 auto;
  width: 100%;

  &__inbound-banner-list {
    margin: 0 auto;
    max-width: 1000px;
  }

  &__terms {
    margin: 100px auto 40px;
    max-width: 1000px;

    @include media-query(md) {
      width: 940px;
    }

    @include media-query(sm) {
      width: 720px;
    }
  }
}
