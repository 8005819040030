@import 'variables';

.article-show-reference {
  background-color: $pale-gray-color;
  padding:          15px 20px;
  position:         relative;

  &__link {
    line-height:     2;
    text-decoration: underline;

    &::after {
      content:     '\A';
      white-space: pre;
    }
  }

  &__subtext {
    font-weight: 700;
  }

  &__reference-row {
    display: flex;
    padding: 5px 0;
  }

  &__reference-text {
    margin:     auto 0;
    word-break: keep-all;
  }

  &__edit-button {
    margin:   4px 4px 0 0;
    position: absolute;
    right:    0;
    top:      0;
  }
}
