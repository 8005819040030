@import 'variables';

$each-block-margin-bottom: 55px;

.facility-show-page {
  margin: 0;
  width:  100%;

  &__inquiry-row {
    margin-bottom: 40px;

    @include media-query(sm) {
      margin: 0 -4vw 20px -3vw;
    }

    @include media-query(xs) {
      margin: 0 -4vw 20px -7vw;
    }

    &--last {
      margin: 0 -4vw 100px;

      @include media-query(xs) {
        margin: 0 -4vw 100px -7vw;
      }
    }
  }

  &__facility-title {
    margin: 25px 0;

    @include media-query(xs) {
      margin: 20px 0;
    }
  }

  &__facility-type {
    color:      $gray-color;
    font-size:  16px;
    margin-top: 13px;

    @include media-query(xs) {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  &__open-date {
    display:     inline-block;
    font-weight: 700;
    margin-top:  15px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__top-blog-link-area {
    color:       $gray-color;
    font-weight: 700;
    margin-top:  15px;
  }

  &__top-blog-count {
    color:     $primary-color;
    font-size: 26px;
  }

  &__top-blog-link-button {
    display:     inline-block;
    margin-left: 15px;

    @include media-query(xs) {
      margin-left: 3px;
    }

    // 本来ならこのようにネストして別ブロックを指定するのは完全にアウトだがどうしてもデザイン上の都合で避けられないことになってしまった
    .yellow-border-button {
      @include media-query(xs) {
        padding: 6px 23px 6px 10px;
      }
    }
  }

  &__top-pictures {
    margin-bottom: 40px;

    @include media-query(xs) {
      margin-bottom: 20px;
    }
  }

  &__minimum-info {
    margin-bottom: 48px;
  }

  &__minimum-info-sp {
    margin-bottom: 20px;
  }

  &__minimum-info-text {
    margin-top:  20px;
    text-align:  center;
  }

  &__user-demographic {
    margin-bottom: 50px;
  }

  &__appeal-points {
    margin-bottom: $each-block-margin-bottom;
  }

  &__keizoku-job-description {
    margin-bottom: $each-block-margin-bottom;
  }

  &__keizoku-use-cases {
    margin-bottom: $each-block-margin-bottom;
  }

  &__employment-results {
    margin-bottom: $each-block-margin-bottom;
  }

  &__staff-introductions {
    margin-bottom: $each-block-margin-bottom;
  }

  &__q-and-a {
    margin-bottom: $each-block-margin-bottom;
  }

  &__facility-features {
    margin-bottom: $each-block-margin-bottom;
  }

  &__basic-information-inquiry {
    margin-top: 20px;
  }

  &__business-hours {
    margin-bottom: 20px;
  }

  &__access {
    margin-bottom: $each-block-margin-bottom;
  }

  &__facility-share {
    flex: 1;

    @include media-query(xs) {
      flex: auto;
      margin-bottom: 10px;
    }
  }

  &__last-update {
    margin: auto 0 0 0;
  }

  &__share-and-last-update {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 20px;

    @include media-query(xs) {
      display: block;
      justify-content: none;
      margin: 50px 0 20px auto;
    }
  }

  &__blog-list {
    margin-bottom: 20px;
  }

  &__blog-header {
    margin-bottom: 35px;
    margin-top: 85px;
  }

  &__blog-count-wrapper {
    color:         $gray-color;
    margin-bottom: 10px;
  }

  &__blog-count {
    color:       $primary-color;
    font-size:   24px;
    font-weight: 700;
  }

  &__blog-index-button {
    align-items:     center;
    border:          3px solid $attention-color;
    border-radius:   40px;
    color:           $text-color;
    display:         flex;
    font-weight:     700;
    height:          65px;
    justify-content: center;
    margin:          0 auto 100px;
    padding:         10px;
    width:           240px;
  }

  &__blog-index-button-icon {
    color:        $primary-color;
    margin-right: 10px;
  }

  &__contents-wrapper {
    width: 780px;

    &--centering {
      margin: 0 auto;
      width:  780px;

      @include media-query(lg) {
        width: 900px;
      }
    }
  }

  &__more-button-wrapper {
    margin-left: auto;
    width: 70%;
  }

  &__contact-inbound-banner {
    margin: 50px auto 30px;
  }

  &__recommended-facilities-wrapper {
    margin: 85px auto 50px;
    width: 720px;
  }
}
