@import 'variables';

.inquiry-block-setting-index {
  &__text-area {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 15px;
  }
}
