@import 'variables';

.top-picture {
  width: 100%;

  &__image {
    width: 100%;
  }

  &__caption {
    background: $light-gray-color;
    color: $text-color;
    padding: 10px;
  }
}
