$thick-green-color: #759c13;
$primary-color: #8eaf29;
$light-green-color: #b6d65c;
$pale-green-color: #f4f7e9;
$secondary-color: #e1fffe;
$text-color: #333;
$text-color-opacity: rgba(0, 0, 0, 0.7);
$light-yellow-color: #fff7c3;
$attention-color: #ffde00;
$white: #fff;
$gray-color: #838383;
$light-gray-color: #d6d6d6;
$pale-gray-color: #ececec;
$light-red-color: #e94d40;
$skeleton-gray-color: rgba(33, 33, 33, 0.8);
$shadow-color: rgba(0, 0, 0, 0.3);
$red: #f00;
$orange: #ff8c00;
$light-blue: #1197ea;
$light-bitter-orange: #f29600;
$white-gray-color: #f5f5f5;

$base-line-height: 1.3;
$large-line-height: 1.5;
$text-line-height: 1.8;
$base-border-radius: 4px;

// 記事内で使っているフォントカラーの共有変数
@value colorGreen #{"" + $thick-green-color};
@value colorOrange #{"" + $orange};
@value colorGray #{"" + $gray-color};

%link {
  color:           $primary-color;
  text-decoration: underline;
}

@mixin media-query($breakpoint) {
  @if $breakpoint == xs {
    @media screen and (max-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint == sm {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @else if $breakpoint == md {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }

  @else if $breakpoint == lg {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }
  @else if $breakpoint == desktop {
    @media screen and (min-width: 992px) {
      @content;
    }
  }
}

@mixin object-fit-cover() {
  font-family: 'object-fit: cover;';
  object-fit:  cover;
}

@mixin object-fit-contain() {
  font-family: 'object-fit: contain;';
  object-fit:  contain;
}
