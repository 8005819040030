@import 'variables';

.inquiry-confirm-modal {
  &__overlay {
    background: $skeleton-gray-color;
    inset: 0;
    position:   fixed;
    z-index:    3;
  }

  &__content {
    background-color: $white;
    border-radius:    $base-border-radius;
    left:             30%;
    outline:          none;
    padding:          15px;
    position:         absolute;
    text-align:       center;
    top:              30%;
    width:            40%;
    z-index:          4;

    @include media-query(xs) {
      left:   10%;
      right:  10%;
      width:  80%;
    }
  }

  &__text {
    font-size:      18px;
    padding-bottom: 20px;
    padding-top:    10%;
  }

  &__privacy-text {
    font-size:      14px;
    padding-bottom: 30px;
  }

  &__close-button {
    display: none;
  }

  &__cancel-button {
    border:        1px solid $gray-color;
    border-radius: 4px;
    font-size:     12px;
    margin-right:  10px;
    padding:       5px 15px;
  }
}
