@import 'variables';

.fade-up {
  animation-delay:     5s;
  animation-duration:  2s;
  animation-fill-mode: forwards;
  animation-name:      fadeUp;
  opacity:             0;

  @keyframes fadeUp {
    from {
      opacity:   0;
      transform: translateY(100px);
    }

    to {
      opacity:   1;
      transform: translateY(0);
    }
  }
}
