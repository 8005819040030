@import 'variables';

.picture-uploader-button {
  %__file-input {
    border-radius: $base-border-radius;
    display:       block;
    font-weight:   700;
    margin:        0 auto 10px;
    padding:       15px;
  }

  &__file-input {
    @extend %__file-input;
    background-color: $white;
    border:           3px solid $text-color;
    color:            $text-color;

    &--uploading {
      @extend %__file-input;
      background-color: $gray-color;
      border:           3px solid $gray-color;
      color:            $white;
    }
  }

  &__file-crop {
    background-color: $white;
    border:           3px solid $text-color;
    border-radius:    $base-border-radius;
    display:          block;
    font-weight:      700;
    margin:           0 auto 10px;
    padding:          15px;
  }
}
