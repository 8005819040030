@import 'variables';

.administer-contract-edit-page {
  &__danger-message {
    margin: 20px auto;
    width:  70%;
  }

  &__danger-submit {
    margin:     20px 0;
    text-align: center;
  }

  &__submit {
    margin:     20px 0;
    text-align: center;
  }

  &__alert-area {
    margin-bottom: 20px;
  }
}
