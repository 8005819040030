@import 'variables';

.check-box {
  display: inline-block;

  + label {
    cursor:   pointer;
    display:  inline-block;
    position: relative;
  }
}

@media (min-width: 1px) {
  .check-box {
    display: none;
    margin:  0;
  }

  .check-box + label {
    padding: 0 0 0 30px;
  }

  .check-box + label::before {
    background: $white;
    border: 2px solid $gray-color;
    box-sizing: border-box;
    content:    '';
    display:    block;
    height:     18px;
    left:       0;
    margin-top: -9px;
    position:   absolute;
    top:        50%;
    width:      18px;
  }

  .check-box:checked + label::after {
    border-bottom: 3px solid $light-red-color;
    border-left:   3px solid $light-red-color;
    box-sizing: border-box;
    content:    '';
    display:    block;
    height:        8px;
    left:          3px;
    margin-top:    -8px;
    position:   absolute;
    top:        50%;
    transform:     rotate(-45deg);
    width:         16px;
  }
}
