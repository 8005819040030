@import 'variables';

.administer-facility-form {
  &__form-items {
    margin-bottom: 30px;
  }

  &__submit {
    text-align: center;
  }
}
