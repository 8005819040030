@import 'variables';

.inquiry-form {
  margin: 0 auto;
  width:  600px;

  @include media-query(xs) {
    width: 100%;
  }

  &__section {
    margin-bottom: 30px;

    @include media-query(xs) {
      margin-bottom: 25px;
    }
  }

  &__input-field {
    flex:      1;
    font-size: 16px;
    width:     100%;
  }

  &__text-area {
    resize: none;
    width:  100%;
  }

  &__text-field {
    width: 100%;
  }

  &__checkbox {
    margin-bottom: 15px;
  }

  &__attention {
    color:       $light-red-color;
    font-size:   16px;
    font-weight: 400;
  }

  &__introduction {
    color:         $text-color;
    line-height:   $text-line-height;
    margin-bottom: 30px;
    text-align:    center;

    @include media-query(xs) {
      margin-bottom: 25px;
    }
  }

  &__introduction-underline {
    background:  linear-gradient(transparent 70%, $attention-color 0%);
    font-weight: 700;
  }

  &__introduction-attention {
    color:       $light-red-color;
    font-weight: 700;
  }

  &__header {
    color:         $text-color;
    font-size:     16px;
    font-weight:   bold;
    margin-bottom: 15px;
    text-align:    center;
  }

  &__facility-name {
    font-size:     18px;
    font-weight:   700;
    margin-bottom: 15px;
    text-align:    center;

    @include media-query(xs) {
      font-size:     18px;
      margin-bottom: 10px;
    }
  }

  &__facility-image {
    margin-bottom: 30px;
    width:         100%;

    @include media-query(xs) {
      margin-bottom: 25px;
    }
  }

  &__notice {
    font-size:  12px;
    text-align: center;
  }

  &__submit {
    font-weight: 700;
    width:       100%;
  }

  &__back {
    font-size:  12px;
    margin:     20px auto;
    text-align: center;
  }

  &__back-button {
    border:        1px solid $gray-color;
    border-radius: $base-border-radius;
    margin:        0 auto;
    padding:       15px;
    text-align:    center;
    width:         30%;
  }

  &__progress {
    margin-bottom: 15px;

    @include media-query(xs) {
      margin-bottom: 10px;
    }
  }

  &__inquiry-after-flow-text {
    padding: 15px;
  }

  &__visit-text {
    color: $gray-color;
    font-size: 12px;
    margin: 13px 0 10px 0;
  }

  &__sub-text {
    color: $gray-color;
    font-size: 12px;
    margin: 0 0 8px 15vw;

    @include media-query(lg) {
      color: $gray-color;
      font-size: 12px;
      margin: 0 0 8px 6vw;
    }
  }
}
