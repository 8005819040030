@import 'variables';

.facility-title {
  color:       $text-color;
  font-size:   22px;
  font-weight: 700;

  @include media-query(xs) {
    font-size: 20px;
  }
}
