@import 'variables';

.biz-edit-picture-stock-page {
  &__form {
    margin-bottom: 20px;
    text-align: center;
  }

  &__attention {
    color: $light-red-color;
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__picture-stock {
    margin-bottom: 20px;
  }

  &__picture-stock-id {
    margin-bottom: 10px;
    text-align: center;
  }

  &__picture {
    display: block;
    margin-bottom: 10px;
  }

  &__delete {
    color: $text-color;
    display: block;
    text-align: center;
    text-decoration: underline;
  }
}
