@import 'variables';

.traffic-value {
  align-items: center;
  color:       $text-color;
  display:     flex;
  font-size:   14px;
  line-height: $large-line-height;
  word-break:  break-all;
}
