@import 'variables';

.login-sidebar {
  border-right: 2px solid $pale-gray-color;
  padding:      20px 10px 0 0;

  &__menu {
    align-items:   center;
    display:       flex;
    margin-bottom: 25px;
    position:      relative;
  }

  &__item {
    color:     $text-color;
    display:   block;
    font-size: 16px;
  }
}
