@import 'variables';

.biz-check-box-area {
  display:   flex;
  flex-wrap: wrap;

  &__item {
    margin-top: 15px;
    padding:    10px;
  }
}
