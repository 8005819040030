@import 'variables';

.contact-confirmation {
  background-color: $white;

  &__header {
    font-size:     16px;
    font-weight:   700;
    margin-bottom: 20px;
    width:         100%;
  }

  &__text {
    line-height:   $large-line-height;
    margin-bottom: 20px;
  }

  &__root-path {
    color: $text-color;
  }
}
