@import 'variables';
@import 'background';

.area-select-facility-wrapper {
  @extend %white-dot-background;
  height:        300px;
  padding:       30px;

  @include media-query(xs) {
    border:        0;
    height:        auto;
    margin-bottom: 0;
    padding:       30px 15px;
    width:         auto;
  }

  @include media-query(sm) {
    border:        0;
    height:        auto;
    margin-bottom: 0;
    padding:       30px 15px;
    width:         auto;
  }

  &__header {
    margin-bottom: 20px;
  }
}
