@import 'variables';

.facility-filters-sp {
  &__hr {
    background-color: $pale-gray-color;
    border:           0;
    height:           1px;
    margin:           0;
  }
}
