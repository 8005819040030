@import 'variables';

.administer-facility-brand-edit {
  &__form-items {
    margin-bottom: 30px;
  }

  &__submit {
    display: block;
    margin:  0 auto;
    width:   50%;
  }
}
