@import 'variables';

.editor-blockquote-input {
  &__forms {
    padding: 20px;
  }

  &__form {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
    padding:         10px;
  }

  &__field {
    width: 80%;
  }

  &__submit {
    text-align: center;
  }

  &__error {
    color:       $red;
    font-size:   14px;
    margin-left: 20%;
  }
}
