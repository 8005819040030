@import 'variables';

.inquiry-facility-card {
  border:        1px solid $light-gray-color;
  border-radius: $base-border-radius;
  display:       flex;
  padding:       15px;

  @include media-query(xs) {
    padding: 10px;
  }

  &__image-wrapper {
    margin-right: 20px;
    width:        30%;

    @include media-query(xs) {
      margin-right: 10px;
    }
  }

  &__image {
    height: auto;
    width:  100%;
  }

  &__texts {
    width: 70%;
  }

  &__name {
    font-weight:   700;
    margin-bottom: 10px;

    @include media-query(xs) {
      margin-bottom: 5px;
    }
  }

  &__address {
    color:     $gray-color;
    font-size: 14px;

    @include media-query(xs) {
      font-size: 12px;
    }
  }
}
