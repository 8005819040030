@import 'variables';

.recommended-facilities-more-button {
  &__content {
    align-items:     center;
    background:      $attention-color;
    border-radius:   $base-border-radius;
    box-shadow:      0 2px 4px $shadow-color;
    display:         flex;
    justify-content: center;
    margin:          0 auto;
    padding:         20px 15px;
    width:           60%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__text {
    font-size:    18px;
    font-weight:  700;
    margin-right: 15px;

    @include media-query(xs) {
      font-size: 16px;
    }
  }
}
