@import 'variables';

.admin-basic-table {
  font-size:    14px;
  table-layout: fixed;
  text-align:   left;
  width:        100%;

  &__header {
    background-color: $primary-color;
    border:           1px solid $primary-color;
    color:            $white;
    padding:          15px;
  }

  %__row {
    border: 1px solid $light-gray-color;
  }

  &__row {
    @extend %__row;

    &--read {
      @extend %__row;
      background-color: $pale-gray-color;
    }

    &--unread {
      @extend %__row;
      background-color: $white;
      font-weight:      700;
    }
  }

  &__data {
    padding: 15px;

    &--warning {
      color: $light-red-color;
    }
  }

  &__key {
    background-color: $primary-color;
    border-bottom:    2px solid $white;
    color:            $white;
    font-size:        14px;
    font-weight:      bold;
    padding:          20px 10px;
    text-align:       center;
    vertical-align:   middle;
    width:            15%;

    @include media-query(xs) {
      width: 25%;
    }
  }

  &__value {
    align-items:      baseline;
    background-color: $white;
    border:           2px solid $light-gray-color;
    border-left:      0;
    line-height:      $base-line-height;
    padding:          20px 25px;
  }

  &__link {
    text-decoration: underline;
  }
}
