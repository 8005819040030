@import 'variables';

.editorial-article-picture-selector {
  &__search-form {
    margin-bottom: 20px;
  }

  &__search-button {
    margin-left: 20px;
  }

  &__picture {
    margin-bottom: 20px;
    max-height:    320px;
  }

  &__submit {
    width: 100%;
  }
}
