@import 'variables';

.inquiry-row-sp {
  justify-content: center;
  margin:          0 auto;
  text-align:      center;

  &__buttons {
    margin-top: 10px;
    width:      100%;
  }

  &__text {
    font-size:     15px;
    line-height:   1.6;
    margin-bottom: 15px;

    &--highlight {
      background: linear-gradient(transparent 70%, $attention-color 0);
    }

    &--attention {
      color: $light-red-color;
    }
  }

  %inquiry-button {
    align-items:     center;
    border-bottom:   solid 4px $shadow-color;
    border-radius:   $base-border-radius;
    box-shadow:      0 2px 4px $shadow-color;
    color:           $text-color;
    display:         flex;
    font-size:       18px;
    height:          80px;
    justify-content: center;
  }

  &__tel-label {
    color:         $light-red-color;
    font-size:     12px;
    font-weight:   700;
    margin-bottom: 7px;
    text-align:    center;
    width:         60%;
  }

  &__web-label {
    color:         $light-red-color;
    font-size:     14px;
    font-weight:   700;
    margin-bottom: 2px;
  }

  &__buttons-area {
    display: flex;
  }
}
