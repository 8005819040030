@import 'variables';

.reactivate {
  text-align: center;

  &__text {
    line-height: $large-line-height;
    margin:      30px 0 50px;
  }

  &__button {
    border:        1px solid $text-color;
    border-radius: 5px;
    cursor:        pointer;
    margin:        0 auto;
    padding:       15px 0;
    width:         20%;
  }
}
