@import 'variables';

%green-dot-background {
  background-color: $white;
  background-image: radial-gradient($light-green-color 10%, transparent 0), radial-gradient($light-green-color 10%, transparent 0);
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

%white-dot-background {
  background-color: $pale-gray-color;
  background-image: radial-gradient($white 10%, transparent 0), radial-gradient($white 10%, transparent 0);
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

%gray-background {
  background-color: $pale-gray-color;
}
