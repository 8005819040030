@import "variables";

.facility-support-breadcrumbs {
  box-sizing: content-box;

  &__wrapper {
    position: relative;
    width:    100%;
  }

  &__text-area {
    display:   flex;
    max-width: 1000px;

    @include media-query(sm) {
      margin:  0;
      padding: 0 12px;
    }
  }

  a {
    text-decoration: underline;
  }

  %breadcrumb-item-style {
    align-items: center;
    display:     flex;
    font-size:   14px;
    height:      40px;
  }

  &__link {
    @extend %breadcrumb-item-style;
  }

  &__text {
    @extend %breadcrumb-item-style;
  }

  &__lines {
    height:       45px;
    margin-left:  20px;
    margin-right: 10px;
    position:     relative;
    top:          -3px;
    width:        15px;
  }

  &__line-1 {
    background-color: $pale-gray-color;
    height:           27px;
    position:         absolute;
    top:              0;
    transform:        rotate(150deg);
    width:            3px;
  }

  &__line-2 {
    background-color: $pale-gray-color;
    bottom:           -4px;
    height:           27px;
    position:         absolute;
    transform:        rotate(-150deg);
    width:            3px;
  }

  &__top-line {
    border-top: 1px solid $pale-gray-color;
  }

  &__bottom-line {
    border-bottom: 8px solid $pale-gray-color;
  }
}
