@import 'variables';

.primary-header {
  padding:          15px 0;

  &__text {
    color: $text-color;
    display:     inline;
    font-size: 24px;
    font-weight: 700;
    line-height: $base-line-height;

    &--green {
      color: $primary-color;
    }
  }
}
