@import 'variables';

.footer {
  background-color: $primary-color;
  padding:          30px 0;
  position:         relative;

  @include media-query(xs) {
    background-color: $white;
    padding:          0;
  }

  &__copyright {
    color:      $white;
    font-size:  12px;
    text-align: center;

    @include media-query(xs) {
      color:          $text-color;
      font-size:      8px;
      padding-bottom: 15px;
    }
  }
}
