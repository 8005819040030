@import 'variables';

.access-page {
  border-bottom:  2px solid $pale-gray-color;
  display:        flex;
  margin-bottom:  35px;
  padding-bottom: 20px;

  @include media-query(xs) {
    border-bottom:  none;
    margin-bottom:  0;
  }

  &__link {
    cursor: pointer;
    margin-left: auto;
  }

  &__map-icon-wrapper {
    color:        $text-color;
    font-size:    14px;
    margin:       auto 20px auto 0;
  }

  &__map-icon {
    height:      31px;
    width:       25px;
  }

  &__map-2-icon {
    height:      50px;
    width:       50px;
  }

  &__map-icon-text {
    font-size: 12px;
    font-weight: 700;
  }

  &__text {
    margin: auto 0;

    @include media-query(lg) {
      font-size: 16px;
    }

    @include media-query(xs) {
      line-height: 1.6;
      margin: auto 0;
    }
  }
}
