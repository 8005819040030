@import 'variables';

.recent-facilities {
  &__title {
    font-size:   24px;
    font-weight: 700;
    margin-top:  20px;
    padding:     20px;
    text-align:  center;
  }

  &__bottom-line {
    border-top: 5px solid $primary-color;
    margin:     15px auto 0;
    width:      100px;
  }

  &__content {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
  }

  &__item {
    border-bottom: 3px solid $light-gray-color;
    margin-bottom: 10px;
    width:         49%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__facility-box {
    display:  flex;
    padding:  10px;
    position: relative;

    @include media-query(xs) {
      padding: 10px 0;
    }
  }

  &__dot {
    color:         $primary-color;
    font-size:     10px;
    padding-right: 5px;
  }

  &__name {
    color:         $text-color;
    flex:          1;
    font-size:     18px;
    font-weight:   bold;
    margin-bottom: 5px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__type {
    color:         $text-color;
    flex:          1;
    font-size:     14px;
    margin-bottom: 5px;

    @include media-query(xs) {
      font-size: 10px;
    }
  }

  &__introduction {
    color:         $text-color;
    flex:          1;
    font-size:     14px;
    font-weight:   700;
    margin-bottom: 5px;

    @include media-query(xs) {
      font-size:     12px;
      margin-bottom: 0;
    }
  }

  &__left-column {
    margin-right: 15px;
    width:        $pc-image-size;

    @include media-query(xs) {
      width: $sp-image-size;
    }
  }

  &__right-column {
    display:         flex;
    flex:            1;
    flex-direction:  column;
    justify-content: space-between;
  }

  &__picture {
    margin: 0 10px 15px 0;
  }

  &__see-more {
    margin-bottom:   5px;
    text-align:      right;
    text-decoration: underline;
  }

  &__see-more-icon {
    display:  inline-block;
    height:   24px;
    position: relative;
    width:    24px;

    &::before {
      color:    $text-color;
      content:  '\f111';
      font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
      left:     3px;
      position: absolute;
      top:      7px;
    }

    &::after {
      color:    $attention-color;
      content:  '\f138';
      font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
      left:     1px;
      position: absolute;
      top:      5px;
    }
  }

  &__border-line {
    border:        1px solid $light-gray-color;
    margin-bottom: 20px;
    width:         90%;
  }
}
