@import 'variables';

.sitemap {
  &__section {
    margin-bottom: 10px;
  }

  &__link {
    @extend %link;
    display: inline-block;
    margin:  0 10px 10px 0;
  }
}
