@import './variables';

.container {
  &--mypage {
    padding-left: 16px;
    padding-right: 16px;

    @media only screen and (min-width: 1000px) {
      padding-left: max(calc((max(100%, 1000px) - 1000px) / 2), 16px);
      padding-right: max(calc((max(100%, 1000px) - 1000px) / 2), 16px);
    }
  }
}
