@import 'variables';

.search-facility-description {
  &__body {
    display: flex;
    padding: 20px 0;

    @include media-query(xs) {
      display: block;
      padding: 10px 0;
    }
  }

  &__image {
    width: 40%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__text {
    color:       $text-color;
    flex:        1;
    font-size:   16px;
    line-height: $text-line-height;
    margin-left: 20px;

    @include media-query(xs) {
      margin-left: 0;
      padding:     20px 0 10px;
      width:       100%;
    }
  }

  &__small-text {
    flex:        1;
    font-size:   14px;
    line-height: $text-line-height;
    margin-left: 15px;

    @include media-query(xs) {
      font-size:   16px;
      margin-left: 0;
      padding:     20px 0 10px;
    }
  }

  &__dual-pane {
    display: flex;

    @include media-query(xs) {
      flex-direction: column;
    }
  }

  &__dual-pane-item {
    width: 50%;

    @include media-query(xs) {
      width: 100%;
    }
  }

  &__button {
    margin: 10px auto 60px;
    width:  250px;

    @include media-query(xs) {
      width: 80%;
    }
  }
}
