@import 'variables';

.administer-inquiry-index-page {
  &__form {
    align-items:     center;
    display:         flex;
    justify-content: space-between;
    margin-bottom:   20px;
    width:           100%;
  }

  &__text-field {
    width: 100px;
  }
}
