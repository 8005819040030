@import 'variables';

.search-icon {
  display:      inline-block;
  height:       32px;
  width:        28px;

  &::before {
    color:   $text-color;
    content: '\f002';
    font:    normal 600 normal 25px/1 "Font Awesome 5 Free";
  }
}
