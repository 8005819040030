@import "variables";

.biz-header {
  align-items:   center;
  border-bottom: 2px solid $pale-gray-color;
  border-top:    5px solid $primary-color;
  display:       flex;
  padding:       10px;

  &__logo {
    height:       35px;
    margin-right: 10px;
  }

  &__pc-logo {
    height:       35px;
    margin-right: 10px;
  }

  &__sp-logo {
    height:   35px;
    left:     0;
    margin:   -20px auto 0;
    position: absolute;
    right:    0;
  }

  &__text {
    font-weight: 700;
  }

  &__link-box {
    display:     flex;
    margin-left: auto;
  }

  &__return-home {
    margin-left: 20px;
  }

  &__icon {
    font-size:      26px;
    vertical-align: middle;
  }
}
