@import 'variables';

.biz-facility-contracts-index-page {
  &__contract-table {
    margin-bottom: 20px;
  }

  &__notice {
    color:      $text-color;
    font-size:  14px;
    margin-top: 5px;
  }
}
