@import 'variables';

.blog-list {
  width: 100%;

  &__card {
    border-top: 2px solid $light-gray-color;
    display:    block;
    padding:    20px 0;
    width:      100%;

    &:last-of-type {
      border-bottom: 2px solid $light-gray-color;
    }
  }
}
