@import 'variables';

.admin-corporation-show-page {
  &__button-row {
    display:       flex;
    flex-wrap:     wrap;
    margin-bottom: 15px;
  }

  &__table {
    margin-bottom: 30px;
  }
}
