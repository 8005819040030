@import 'variables';

.select-conditions-page-xs {
  %__header {
    font-weight: 700;
    padding:     20px 15px;
    width:       100%;
  }

  &__header {
    @extend %__header;
  }

  &__second-header {
    @extend %__header;
    background-color: $pale-gray-color;
  }

  &__check-box-wrapper {
    border-bottom: $primary-color dashed 1px;
    display:       flex;
    padding:       25px 15px;

    &:first-of-type {
      border-top: $primary-color dashed 1px;
    }
  }

  &__check-box {
    flex: 1;
  }
}
