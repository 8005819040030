@import 'variables';

.secondary-header {
  padding:    15px 0;
  text-align: center;

  &__text {
    color:       $text-color;
    font-size:   24px;
    font-weight: 700;
    white-space: pre-line;

    &--green {
      color: $primary-color;
    }
  }

  &__bottom-line {
    border-radius: 2px;
    border-top:    5px solid $primary-color;
    content:       '';
    margin:        15px auto 0;
    width:         100px;
  }
}
