@import 'variables';

.facility-type-top-page {
  padding-top: 25px;

  @include media-query(xs) {
    padding-top: 15px;
  }

  &__header {
    margin-bottom: 25px;

    @include media-query(xs) {
      margin-bottom: 15px;
    }
  }

  &__item {
    margin-bottom: 20px;
  }

  &__gray_background {
    background-color: $pale-gray-color;
    padding:          20px;
  }
}
