@import 'variables';

.frequently_asked_question-form {
  &__field {
    display:       block;
    height:        40px;
    margin-bottom: 30px;
    width:         100%;
  }

  &__big-field {
    display:       block;
    height:        150px;
    margin-bottom: 30px;
    width:         100%;
  }
}
