@import 'variables';

.breadcrumb {
  background-color: $pale-gray-color;

  &__links {
    color:     $gray-color;
    font-size: 14px;
    padding:   10px 0;
    width:     100%;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--white-gray-background {
    background-color: $white-gray-color;
  }
}
