@import 'variables';

.footer-blog-list {
  padding-top: 30px;
  width:       100%;

  @include media-query(md) {
    margin: 0 auto;
    width:  780px;
  }

  @include media-query(lg) {
    margin: 0 auto;
    width:  780px;
  }

  &__content {
    margin-bottom: 50px;
    padding-top:   20px;
  }
}
