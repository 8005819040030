@import 'variables';

.editorial-department-article-form {
  margin:         0 auto;
  max-width:      700px;
  padding-bottom: 120px;

  &__label {
    font-weight:   700;
    margin-bottom: 5px;
  }

  &__label-row {
    display:         flex;
    justify-content: space-between;
  }

  &__summary-counter {
    color: $gray-color;
    font-size:   14px;
    font-weight: 400;
  }

  &__text-field {
    margin-bottom: 20px;
    width:         100%;
  }

  &__top-picture {
    max-width: 700px;
    width:     100%;
  }

  &__summary-field {
    display:       block;
    height:        100px;
    margin-bottom: 20px;
    resize:        none;
    width:         100%;
  }

  &__body-field {
    display:       block;
    height:        300px;
    margin-bottom: 20px;
    resize:        none;
    width:         100%;
  }

  &__select-row {
    display:         flex;
    justify-content: flex-end;
  }

  &__select {
    margin: 0 20px 20px 0;
  }

  &__submit {
    bottom:     0;
    padding:    20px 4px;
    position:   fixed;
    right:      100px;
    text-align: center;
  }
}
