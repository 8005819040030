@import 'variables';

.purpose-select-button {
  align-items:      stretch;
  background-color: $white;
  border:           2px solid $primary-color;
  border-radius:    $base-border-radius;
  color:            $text-color;
  display:          flex;
  text-decoration:  none;
  width:            100%;

  &__left-area {
    align-items:     center;
    display:         flex;
    flex:            1;
    justify-content: center;
    padding:         15px;
  }

  &__icon-area {
    display:    block;
    margin:     0 15px 0 0;
    text-align: center;
  }

  &__icon {
    width: 40px;
  }

  &__main-text {
    display:       block;
    font-size:     18px;
    font-weight:   bold;
  }

  &__sub-text {
    font-size: 12px;
  }

  &__right-area {
    background:      $attention-color;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    width:           30px;
  }

  &__arrow {
    display: block;
    margin:  0 auto;
    width:   10px;
  }
}
