@import 'variables';

.fourthly-header {
  align-items: center;
  display:     flex;
  padding:     10px 0;

  &::before {
    background: $primary-color;
    content: '';
    display: inline-block;
    height: 22px;
    margin-right: 6px;
    width: 4px;
  }

  &__text {
    color:       $text-color;
    font-size:   20px;
    font-weight: 700;
  }

  &__supplement {
    color:       $text-color;
    font-size:   18px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }
}
