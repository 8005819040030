@import 'variables';

.blog-article-editor-button-list {
  &__button {
    text-align: center;
    width:      100%;

    &:hover,
    &:focus {
      background: $pale-gray-color;
      outline:    0;
    }
  }

  &__button-icon {
    font-size:    16px;
    margin-right: 5px;
  }

  &__button-text {
    font-size:   16px;
    font-weight: 700;
  }
}
