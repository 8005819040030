@import 'variables';

.administer-bill-index-page {
  &__form {
    margin-bottom: 20px;
  }

  &__table {
    // important使ったらダメなのは分かってるがこのelementとマルチクラスで指定されているbiz-basic-tableに
    // font-size 18pxが指定されていてどうしても上書きたかったので許してクレメンス
    font-size: 14px !important;
  }

  &__search {
    display: flex;
    margin:  10px 0;
  }

  &__search-item {
    margin-right: 20px;
  }
}
