@import 'variables';

.yellow-button {
  background-color: $attention-color;
  border-radius:    40px;
  padding:          15px 22px 15px 15px;
  position:         relative;
  text-align:       center;

  &__text {
    color:       $text-color;
    font-size:   16px;
    font-weight: 700;
  }

  &__arrow {
    position: absolute;
    right:    15px;
    top:      calc(50% - (15px / 2));
  }
}
