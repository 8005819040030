@import 'variables';

.facility-feature-list {
  display: flex;
  padding: 20px 0;

  @include media-query(xs) {
    -webkit-overflow-scrolling: touch;
    overflow-x:         scroll;
  }

  &__item {
    flex:    1;
    padding: 0 10px;

    @include media-query(xs) {
      flex:    0 0 88%;
      padding: 0 5px;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  &__banner {
    height:        0;
    margin-bottom: 10px;
    overflow:      hidden;
    padding-top:   56.25%;
    position:      relative;
  }

  &__banner-text {
    align-items:     center;
    color:           $white;
    display:         flex;
    font-size:       18px;
    font-weight:     700;
    height:          100%;
    justify-content: center;
    left:            0;
    position:        absolute;
    text-align:      center;
    top:             0;
    width:           100%;
  }

  &__image {
    @include object-fit-cover();
    height:   100%;
    left:     0;
    position: absolute;
    top:      0;
    width:    100%;
  }

  &__text {
    color:       $text-color;
    font-size:   14px;
    line-height: $large-line-height;
  }
}
