@use "sass:math";
@import 'variables';
@import 'background';

.prefecture-select-modal {
  &__overlay {
    background: $skeleton-gray-color;
    inset:     0;
    position:   fixed;
    z-index:    3;
  }

  &__content {
    @extend %white-dot-background;
    border-radius: $base-border-radius;
    height:        70%;
    left:          100px;
    outline:       none;
    padding:       15px;
    position:      absolute;
    right:         100px;
    top:           100px;
    z-index:       4;

    @include media-query(sm) {
      height:     90%;
      inset:      30px 15px 50px 15px;
      overflow-y: auto;
      padding:    20px;
    }

    @include media-query(xs) {
      height:     90%;
      inset:      30px 15px 50px 15px;
      overflow-y: auto;
      padding:    20px;
    }

    &__header {
      font-size:   30px;
      font-weight: 700;
      padding:     20px;
      text-align:  center;
    }
  }

  &__close-button {
    $pc-button-width: 50px;
    background-color: $white;
    border:           4px solid $attention-color;
    border-radius:    50%;
    color:            $text-color;
    font-size:        26px;
    height:           $pc-button-width;
    position:         absolute;
    right:            math.div($pc-button-width, 2);
    top:              math.div($pc-button-width, 2);
    width:            $pc-button-width;
    z-index:          10;

    @include media-query(sm) {
      $sp-button-width: 40px;
      font-size: 24px;
      height:    $sp-button-width;
      right:     math.div($sp-button-width, 2);
      top:       math.div($sp-button-width, 2);
      width:     $sp-button-width;
    }

    @include media-query(xs) {
      $sp-button-width: 40px;
      font-size: 24px;
      height:    $sp-button-width;
      right:     math.div($sp-button-width, 2);
      top:       math.div($sp-button-width, 2);
      width:     $sp-button-width;
    }
  }
}
