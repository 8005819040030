@import 'variables';

.facility-brand-appeal-point-form-item {
  &__text-field {
    width: 100%;
  }

  &__container {
    display: flex;
  }

  &__image {
    flex:         1;
    margin-right: 15px;
  }

  &__content {
    flex: 1;
  }

  &__text-area {
    min-height: 250px;
    width:      100%;
  }
}
