@import 'variables';

$pc-image-size: 120px;
$sp-image-size: 80px;

.article {
  display:  flex;
  padding:  15px;
  position: relative;

  @include media-query(xs) {
    padding: 15px 0;
  }

  &__left-box {
    margin-right: 15px;
    width:        $pc-image-size;

    @include media-query(xs) {
      width: $sp-image-size;
    }
  }

  &__image {
    @include object-fit-cover();
    height: $pc-image-size;
    width:  $pc-image-size;

    @include media-query(xs) {
      height: $sp-image-size;
      width:  $sp-image-size;
    }
  }

  &__right-box {
    display:         flex;
    flex:            1;
    flex-direction:  column;
    justify-content: space-between;
  }

  &__title {
    color:         $text-color;
    flex:          1;
    font-size:     18px;
    font-weight:   bold;
    line-height:   $base-line-height;
    margin-bottom: 15px;

    @include media-query(xs) {
      font-size: 14px;
    }
  }

  &__read-more {
    color:           $text-color;
    text-align:      right;
    text-decoration: underline;
  }

  &__read-more-icon {
    display:  inline-block;
    height:   24px;
    position: relative;
    width:    24px;

    &::before {
      color:    $text-color;
      content:  '\f111';
      font:     normal 600 normal 22px/1 "Font Awesome 5 Free";
      left:     3px;
      position: absolute;
      top:      7px;
    }

    &::after {
      color:    $attention-color;
      content:  '\f138';
      font:     normal 600 normal 25px/1 "Font Awesome 5 Free";
      left:     1px;
      position: absolute;
      top:      5px;
    }
  }

  &__pickup-label {
    border-color: $primary-color transparent transparent;
    border-style: solid;
    border-width: 50px 50px 0 0;
    height:       0;
    left:         0;
    position:     absolute;
    top:          0;
    width:        0;
  }

  &__pickup-label-text {
    color:     $white;
    font-size: 10px;
    left:      0;
    position:  absolute;
    top:       13px;
    transform: rotate(-45deg);
  }
}
