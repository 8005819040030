@import 'variables';

.danger-message {
  background:    $white;
  border:        2px solid $light-red-color;
  border-radius: $base-border-radius;
  color:         $light-red-color;
  font-size:     16px;
  padding:       15px;

  &__row {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::before {
      content:      '\f111';
      font:         normal 600 normal 14px/1 "Font Awesome 5 Free";
      margin-right: 5px;
    }
  }
}
