@import 'variables';

.editor-toolbar {
  background:    $skeleton-gray-color;
  border-radius: $base-border-radius;
  box-shadow:    0 5px 5px 0 $pale-gray-color;
  color:         $white;
  cursor:        auto;
  max-width:     700px;
  position:      absolute;
  transition:    all 0.1s ease;
  z-index:       2;

  &__button {
    color:   $white;
    cursor:  pointer;
    display: inline-block;
    padding: 6px 10px;

    &--active {
      background:    $text-color;
      border-radius: $base-border-radius;
    }

    &--orange {
      color: $orange;
    }

    &--gray {
      color: $gray-color;
    }

    &--green {
      color: $primary-color;
    }
  }
}
