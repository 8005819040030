@import 'variables';

.danger-submit {
  background-color: $light-red-color;
  border-radius:    $base-border-radius;
  color:            $white;
  cursor:           pointer;
  font-size:        20px;
  padding:          10px 30px;
}
