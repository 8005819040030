@import 'variables';

.article-index-page {
  &__header {
    font-size:   30px;
    font-weight: 900;
    padding:     20px 0;
  }

  &__green {
    color: $primary-color;
  }

  &__gray-background {
    background-color: $pale-gray-color;
  }

  &__article-list {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
    padding:         30px 0;
  }

  &__article {
    margin-bottom: 20px;
    width:         49%;

    @include media-query(xs) {
      width: 100%;
    }
  }
}
