@import 'variables';

.low-grade-facility-brand-card {
  background:    $white;
  border-radius: $base-border-radius;
  height:        100%;
  padding:       15px;

  &__logo-area {
    height: 50px;
    margin: 0 auto 15px;
    width:  auto;

    @include media-query(xs) {
      height: 40px;
    }
  }

  &__logo {
    height:     100%;
    object-fit: contain;
    width:      100%;
  }

  &__content {
    flex: 1;
  }

  &__name {
    color:         $gray-color;
    font-size:     14px;
    margin-bottom: 20px;

    @include media-query(xs) {
      font-size:     10px;
      margin-bottom: 15px;
    }
  }

  &__catchphrase {
    color:         $text-color;
    font-size:     16px;
    font-weight:   700;
    margin-bottom: 20px;

    @include media-query(xs) {
      font-size:     12px;
      margin-bottom: 15px;
    }
  }
}
