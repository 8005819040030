@import 'variables';

.inquiry-input-wrapper {
  align-items:     center;
  display:         flex;
  justify-content: space-between;

  &__label {
    display:      inline-block;
    font-size:    14px;
    font-weight:  700;
    margin-right: 10px;
    width:        15%;

    @include media-query(xs) {
      font-size:    12px;
      margin-right: 5px;
    }
  }

  &__input {
    flex: 1;
  }
}
