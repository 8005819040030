@import 'variables';

.facility-support-article-group-category-button {
  background-color: $primary-color;
  border-radius:    $base-border-radius;
  box-shadow:       0 2px 4px $shadow-color;
  color:            $white;
  cursor:           pointer;
  display:          block;
  font-size:        20px;
  margin-bottom:    30px;
  padding:          20px 30px;
  text-align:       center;
  width:            300px;
}
