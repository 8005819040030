@import 'variables';

.facility-brand-appeal-points-edit {
  padding-bottom: 100px;

  &__submit-area {
    background: $skeleton-gray-color;
    bottom:     0;
    left:       0;
    padding:    15px 0;
    position:   fixed;
    right:      0;
  }

  &__submit {
    display: block;
    margin:  0 auto;
  }
}
