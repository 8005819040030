@import 'variables';

.inquiry-web-button-sp {
  width: 100%;

  %inquiry-button {
    align-items:     center;
    border-bottom:   solid 4px $shadow-color;
    border-radius:   $base-border-radius;
    box-shadow:      0 2px 4px $shadow-color;
    color:           $text-color;
    display:         flex;
    font-size:       18px;
    height:          80px;
    justify-content: center;
  }

  &__form {
    width: 100%;
  }

  &__button {
    @extend %inquiry-button;
    background-color: $white;
    margin-right:     10px;
    text-align:       center;
    width:            29vw;

    &--big {
      @extend %inquiry-button;
      background-color: $attention-color;
      margin-top:       15px;
      width:            100%;
    }
  }

  &__label {
    align-items: center;
    background-color: $white;
    border: 1px solid $light-gray-color;
    border-radius: 18px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    box-sizing: border-box;
    color: $text-color;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    height: 35px;
    justify-content: center;
    padding-left: 8px;
    position: relative;
  }

  &__label-wrapper {
    margin: 5px 0;

    &:last-child {
      margin: 0 0 15px;
    }
  }

  &__bottom-line {
    border-top: 5px solid $primary-color;
    content: '';
    margin: 15px auto;
    width: 100px;
  }

  %text {
    color:       $text-color;
    font-weight: 700;
  }

  &__text {
    @extend %text;
    font-size:   14px;
    width: 100%;

    &--small {
      @extend %text;
      font-size:  12px;
      text-align: center;
    }

    &--default {
      @extend %text;
      font-size: 18px;
    }
  }

  &__inquiry-icon {
    height: 23px;
    margin: 0 5px;
  }
}
