@import 'variables';

.biz-inquiry-setting-page {
  &__text-block {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 15px;
  }
}
