@import 'variables';

.complete-page {
  color:  $text-color;
  margin: 0 auto;
  width:  75%;

  @include media-query(xs) {
    width: 90%;
  }
}
