@import 'variables';

.slick-dots {
  background-color: $pale-gray-color;
  padding:          10px 7.5px;
  position:         static;
  text-align:       left;

  li {
    height: auto;
    margin: 0;
    width:  10%;
  }
}
