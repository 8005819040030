@import 'variables';

.accounts-table {
  width: 100%;

  &__cell {
    padding:    5px 10px;
    text-align: right;
  }
}
