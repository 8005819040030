@import 'variables';

.blog-card {
  &__title {
    color:         $primary-color;
    font-size:     18px;
    font-weight:   700;
    margin-bottom: 10px;
  }

  &__facility-name {
    color:         $gray-color;
    font-size:     14px;
    margin-bottom: 15px;
  }

  &__picture-and-summary {
    display: flex;
  }

  &__top-picture {
    height:       110px;
    margin-right: 10px;
    object-fit:   cover;
    width:        110px;
  }

  &__summary {
    color:     $gray-color;
    flex:      1;
    font-size: 14px;
  }

  &__open-date-and-link {
    color:           $gray-color;
    display:         flex;
    justify-content: space-between;
    margin-top:      10px;
    width:           100%;
  }

  &__calendar {
    font-size: 15px;
  }

  &__more-link {
    color:    $text-color;
    position: relative;
    width:    100px;
  }

  &__more-link-icon {
    font-size: 9px;
    position:  absolute;
    top:       2px;
  }
}
