@import 'variables';

.article-show-page {
  margin: 0 auto;
  max-width: 700px;
  padding-bottom: 40px;
  padding-top: 20px;

  &__title {
    font-size: 30px;
    font-weight: 900;
    line-height: $large-line-height;
    margin-bottom: 20px;

    @include media-query(xs) {
      font-size: 24px;
    }
  }

  &__modified-at-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include media-query(xs) {
      margin-bottom: 8px;
    }
  }

  &__share-button-box {
    margin-top: 30px;
    text-align: right;
  }

  &__share-buttons {
    display: inline-flex;
  }

  &__modified-at {
    color: $gray-color;
    font-size: 16px;
  }

  &__fluid-block {
    background-color: $light-gray-color;
    margin-bottom: 20px;
  }

  &__top-picture-wrapper-wrapper {
    margin: 0 auto;
    max-width: 700px;
  }

  &__top-picture-wrapper {
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }

  &__top-picture {
    @include object-fit-cover();
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__summary {
    color: $text-color;
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 40px;
    text-align: justify;
    word-wrap: break-word;
  }

  &__body {
    margin-bottom: 40px;
    width: 100%;
  }

  &__supervisor {
    margin-bottom: 20px;
  }

  &__section-end {
    margin-bottom: 40px;
  }

  &__text {
    color: $text-color;
  }
}
